import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { ShowSnack } from '../features/loginSlice';

function VerifyModal({ ShowOTPModal }) {
    const dispatches = useDispatch();
    const [checked, setchecked] = useState(false);
    return (
        <>
            <div className='modal1'>
                <div className='outerscreen1'>
                    <div className="innerscreen">
                        <h4>Declaration by Field Engineer / Supervisor / On-Site Engineer / On-Site FE Team Leader </h4>
                        <div className="modalinput">
                            <div style={{display:'flex', flexDirection:"row"}}>
                                <input type="checkbox" style={{width:'10%'}} checked={checked} onChange={() => setchecked(!checked)} id='chk' name='chk' />
                                <label htmlFor="chk" style={{padding:'10px'}}>
                                    I  hereby declare that I have verified the original documents  ( POA , POI & Photograph) attached with this CAF and found in order and correct.
                                </label>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                                <button className='btn2' onClick={() => {
                                    if (checked === true) {
                                        ShowOTPModal();
                                    } else {
                                        dispatches(ShowSnack({ msg: ' Please check the declaration first. ', time: 4000 }))
                                    }

                                }}>Verify</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyModal