import React from 'react'

function Footer() {
    return (
        <>

            <div className="footer">
                <p>@copyright FHNPL</p>
            </div>
        </>
    )
}

export default Footer