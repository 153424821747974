import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Decypt, tkn, url } from '../App';
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import Navigation from './Navigation'
import SalarySlip from './SalarySlip';
import Sidebar from './Sidebar'

function SalaryDetails() {
    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const year = new Date().getFullYear();
    const currentmonth = new Date().getMonth();
    const salaryslipinit = {
        month: months[currentmonth],
        year: year,
        uid: '',
        designation: '',
        name: '',
        department: "",
        workingdays: '',
        leaveallowed: '',
        leavetaken: '',
        absent: '',
        amountpayable: '',
        accountnumber: '',
        bankname: '',
        basicpay: '',
        hra: "",
        conveyanceallowance: '',
        medicalallowance: '',
        specialallowance: "",
        additionalperks: "",
        otherallowances: '',
        specialefforts: '',
        grosssalary: '',
        amounttobank: '',
        amountwithheldifany: '',
        professionaltax: '',
        othertax: '',
        epf: '',
        advancetaken: '',
        loanemi: '',
        insurance: '',
        leave_absent: '',
        totaldeduction: ''

    }
    const [refreshlist, setrefreshlist] = useState(false)
    const [salaryslip, setsalaryslip] = useState(salaryslipinit);
    const [viewslip, setviewslip] = useState({ year: year, month: months[currentmonth], uid: '' })
    const [slip, setslip] = useState(null)
    const CreateSlip = () => {

        if (salaryslip.uid === '') {
            dispatches(ShowSnack({ msg: 'Enter UID. ', time: 4000 }))
        } else if (salaryslip.basicpay === '') {
            dispatches(ShowSnack({ msg: 'Enter Basic Pay. ', time: 4000 }))
        } else if (salaryslip.grosssalary === '') {
            dispatches(ShowSnack({ msg: 'Enter Gross Salary. ', time: 4000 }))
        } else if (salaryslip.accountnumber === '') {
            dispatches(ShowSnack({ msg: 'Enter Account Number. ', time: 4000 }))
        } else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/addsalary/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr, salaryslip })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setrefreshlist(!refreshlist);
                        setsalaryslip({ ...salaryslipinit })
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }

    }

    function ViewPaySlip() {
        if (viewslip.month === '') {
            dispatches(ShowSnack({ msg: 'Please select month. ', time: 4000 }))
        } else if (viewslip.year === '') {
            dispatches(ShowSnack({ msg: 'Year is required. ', time: 4000 }))
        } else if (viewslip.uid === '') {
            dispatches(ShowSnack({ msg: 'UID is required. ', time: 4000 }))
        } else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/viewpayslip/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr, viewslip })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setslip(res.msg);

                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }
    }


    return (
        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser1' >

                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>

                            <div className='forminput' style={{ width: '100%' }}>
                                <h2 style={{ color: "#b47104", textDecoration: "underline" }}>Salary</h2>
                            </div>

                            {/* <div className='forminput'>
                                <label htmlFor="irla">Employee ID:</label>
                                <input type="text" className='inputtxt1' value={''} onChange={(e) => { }} onBlur={() => {
                                    // Call api to fetch employee details
                                }} />

                            </div> */}

                            <div className='forminput'>
                                <label htmlFor="irla">Month: </label>
                                <select className='inputtxt1' value={salaryslip.month} onChange={(e) => { salaryslip.month = e.target.value; setsalaryslip({ ...salaryslip }) }}>
                                    {
                                        months.map((val, indx) => (
                                            <option key={indx} value={val}>{val}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Year:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.year} onChange={(e) => { salaryslip.year = e.target.value; setsalaryslip({ ...salaryslip }) }} />

                            </div>

                            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>Employee Information</h4>
                            <div className='forminput'>
                                <label htmlFor="irla">UID (Employee ID):</label>
                                <input type="text" className='inputtxt1' value={salaryslip.uid} onChange={(e) => { salaryslip.uid = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Designation:</label>
                                <input type="text" className='inputtxt1' value={salaryslip.designation} onChange={(e) => { salaryslip.designation = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Name:</label>
                                <input type="text" className='inputtxt1' value={salaryslip.name} onChange={(e) => { salaryslip.name = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Department:</label>
                                <input type="text" className='inputtxt1' value={salaryslip.department} onChange={(e) => { salaryslip.department = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>Attendance</h4>
                            <div className='forminput'>
                                <label htmlFor="irla">Working Days:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.workingdays} onChange={(e) => { salaryslip.workingdays = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>



                            <div className='forminput'>
                                <label htmlFor="irla">Leave Allowed:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.leaveallowed} onChange={(e) => { salaryslip.leaveallowed = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>



                            <div className='forminput'>
                                <label htmlFor="irla">Leave Taken:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.leavetaken} onChange={(e) => { salaryslip.leavetaken = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Absent:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.absent} onChange={(e) => { salaryslip.absent = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>



                            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>Salary Calculation</h4>
                            <h5 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>Cost To Compant CTC/Month</h5>
                            <div className='forminput'>
                                <label htmlFor="irla">Basic Pay:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.basicpay} onChange={(e) => { salaryslip.basicpay = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">House Rental Allowance:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.hra} onChange={(e) => { salaryslip.hra = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Conveyance Allowance:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.conveyanceallowance} onChange={(e) => { salaryslip.conveyanceallowance = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Medical Allowance:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.medicalallowance} onChange={(e) => { salaryslip.medicalallowance = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Special Allowance:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.specialallowance} onChange={(e) => { salaryslip.specialallowance = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Additional Perks:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.additionalperks} onChange={(e) => { salaryslip.additionalperks = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Other Allowance:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.otherallowances} onChange={(e) => { salaryslip.otherallowances = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Special Efforts:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.specialefforts} onChange={(e) => { salaryslip.specialefforts = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Gross Salary:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.grosssalary} onChange={(e) => { salaryslip.grosssalary = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Amount To Bank:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.amounttobank} onChange={(e) => { salaryslip.amounttobank = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Amount With held if any:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.amountwithheldifany} onChange={(e) => { salaryslip.amountwithheldifany = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <h5 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>Deduction</h5>
                            <div className='forminput'>
                                <label htmlFor="irla">Professional Tax:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.professionaltax} onChange={(e) => { salaryslip.professionaltax = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Other Tax:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.othertax} onChange={(e) => { salaryslip.othertax = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">EPF:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.epf} onChange={(e) => { salaryslip.epf = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Advance Taken:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.advancetaken} onChange={(e) => { salaryslip.advancetaken = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Loan EMI:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.loanemi} onChange={(e) => { salaryslip.loanemi = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Insurance:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.insurance} onChange={(e) => { salaryslip.insurance = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Leave/ Absent:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.leave_absent} onChange={(e) => { salaryslip.leave_absent = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Total Deductions:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.totaldeduction} onChange={(e) => { salaryslip.totaldeduction = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>Salary Details</h4>


                            <div className='forminput'>
                                <label htmlFor="irla">Amount Payable:</label>
                                <input type="number" className='inputtxt1' value={salaryslip.amountpayable} onChange={(e) => { salaryslip.amountpayable = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Account Number:</label>
                                <input type="text" className='inputtxt1' value={salaryslip.accountnumber} onChange={(e) => { salaryslip.accountnumber = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Bank Name:</label>
                                <input type="text" className='inputtxt1' value={salaryslip.bankname} onChange={(e) => { salaryslip.bankname = e.target.value; setsalaryslip({ ...salaryslip }) }} />
                            </div>


                            <div className='forminput' style={{ width: '100%' }}>
                                <button onClick={() => { CreateSlip() }} className='btn1'>Create Slip</button>
                            </div>

                        </div>

                        <hr /> <hr />



                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>View PaySlip</h4>

                            <div className='forminput'>
                                <label htmlFor="irla">Month: </label>
                                <select className='inputtxt1' value={viewslip.month} onChange={(e) => { viewslip.month = e.target.value; setviewslip({ ...viewslip }) }}>
                                    {
                                        months.map((val, indx) => (
                                            <option key={indx} value={val}>{val}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Year:</label>
                                <input type="number" className='inputtxt1' value={viewslip.year} onChange={(e) => { viewslip.year = e.target.value; setviewslip({ ...viewslip }) }} />

                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">UID (Employee ID):</label>
                                <input type="text" className='inputtxt1' value={viewslip.uid} onChange={(e) => { viewslip.uid = e.target.value; setviewslip({ ...viewslip }) }} />

                            </div>


                            <div className='forminput' style={{ width: "100%", alignItems: 'flex-end' }}>
                                <button style={{backgroundColor:"#b47104"}} onClick={() => { ViewPaySlip() }} className='btn1'>View</button>
                            </div>

                        </div>




                        {
                            slip===null?
                            <>
                            <h4 style={{ width: "100%", color: "brown", paddingTop: '10px' , textAlign:"center"}}>No Payslip</h4>
                            </>:
                            <SalarySlip salaryslip={slip}/>
                        }


         
                    </div>


                </div>
            </div>




        </>
    )
}

export default SalaryDetails