import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Decypt, tkn, url } from '../App'
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice'
import Footer from './Footer'
import Navigation from './Navigation'
import Sidebar from './Sidebar'


function WorkDone() {
    const date = new Date();
    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const [input, setinput] = useState(date.toISOString().slice(0, 10))
    const [input2, setinput2] = useState(date.toISOString().slice(0, 10))
    const [worklist, setworklist] = useState(null);
    const [refreshlist, setrefreshlist] = useState(false)

    useEffect(() => {

        dispatches(Showloading("Retrieving Users, Please wait..."));
        fetch(`${url}userview/req/worklistadmin/`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'usr_ath': window.btoa(tkn)
            },
            body: JSON.stringify({ user: uselect.usr, input, input2 })
        }).then(result => result.json())
            .then(res => {
                res = JSON.parse(Decypt(res));
                // console.log(res);

                if (res.status === 'ok') {
                    // reset
                    setworklist(res.msg);


                } else {
                    // console.log(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                }

            }).catch(err => {
                dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
            }).finally(() => {
                dispatches(HideLoading());
            })

        return () => {

        }
    }, [refreshlist])

    const UpdateWork = (btnaction, indx) => {
        if (worklist[indx].adminreply === '') {
            dispatches(ShowSnack({ msg: 'Reply should not be blank. ', time: 4000 }))
        } else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/adminreply/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr, adminreply:worklist[indx].adminreply, workid:worklist[indx].workid, workstatus:btnaction })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setrefreshlist(!refreshlist);
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }

    }

    const ViewWork = () => {
        // console.log(input, input2)
        if (input === '') {
            dispatches(ShowSnack({ msg: 'Please Enter from date first. ', time: 4000 }))
        } else if (input2 === '') {
            dispatches(ShowSnack({ msg: 'Please Enter To date first. ', time: 4000 }))
        } else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/worklistadmin/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr, input, input2 })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setworklist(res.msg);
                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }

    }
    return (
        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser1' >
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>

                            <div className='forminput'>
                                <label htmlFor="irla">From: </label>
                                <input type="date" className='inputtxt1' value={input} onChange={(e) => { setinput(e.target.value) }} />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">To: </label>
                                <input type="date" className='inputtxt1' value={input2} onChange={(e) => { setinput2(e.target.value) }} />
                            </div>

                            <div className='forminput'>
                                <button onClick={ViewWork} className='btn1'>View</button>
                            </div>

                        </div>



                        {
                            worklist === null ? <> <h3 style={{ color: "blue", textAlign: 'center' }}>No Work Description Found</h3></> :
                                worklist.map((val, indx) => {
                                    return (

                                        <div className='workdonedecision' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: "center" }}>

                                            <div className='forminput'>
                                                <p style={{ color: "#000" }}>

                                                    <b> On {val.workcreatedon.substring(0, 10)}, Task By {val.user} :</b>  <br />
                                                    {val.workdescription}
                                                </p>
                                            </div>

                                            <div className='forminput'>
                                                <label htmlFor="irla">Reply: </label>
                                                <textarea value={val.adminreply} onChange={(e) => {
                                                    worklist[indx].adminreply = e.target.value;
                                                }} className='inputtxt1' cols="30" rows="5"
                                                disabled={val.workstatus === 'Pending'?false:true}
                                                ></textarea>

                                            </div>

                                            {
                                                val.workstatus === 'Pending' ?
                                                    <div className='forminput' style={{ display: 'flex', flexDirection: "row", justifyContent: "space-around" }}>
                                                        <button onClick={() => UpdateWork('Rejected', indx)} className='btn2' style={{ width: '45%', backgroundColor: 'brown' }}>Reject</button>
                                                        <button onClick={() => UpdateWork('Approved', indx)} className='btn2' style={{ width: '45%' }}>Approve</button>
                                                    </div> :
                                                    <div className='forminput' style={{ display: 'flex', flexDirection: "row", justifyContent: "space-around" }}>
                                                       <h4 style={val.workstatus==='Rejected'?{color:"brown"}:val.workstatus==='Approved'?{color:"green"}:{}}> {val.workstatus} </h4>
                                                    </div>
                                            }

                                        </div>

                                    );
                                })
                        }






                    </div>


                </div>
            </div>




        </>
    )
}

export default WorkDone