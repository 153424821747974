import { createSlice } from "@reduxjs/toolkit";

const initialState={
    usr:'',
    logggedIn:false,
    sesstkn:'',
    localtkn:'',
    usertype :'',
    apikey :'',
    loading:false,
    loadingtxt:'Please Wait',
    snack:false,
    snackmsg:'Welcome to FHNPL',
    snacktime:'3000'
    
}

export const loginSlice =createSlice({
    name:'loginSlice',
    initialState,
    reducers:{
        LoginNow:(state, action) => {
            state.usr= action.payload.usr;
            state.logggedIn=true;
            state.localtkn= action.payload.localtkn;
            state.sesstkn = action.payload.sesstkn;
            state.usertype=action.payload.usertype;
            state.apikey=action.payload.apikey;
        },
        Logout:(state) => {
            state.usr= '';
            state.logggedIn=false;
            state.localtkn= '';
            state.sesstkn = '';
            state.usertype='';
            state.apikey='';
        },
        Showloading:(state, action) => {
            state.loading=true;
            state.loadingtxt= action.payload;
        },
        HideLoading:(state) => {
            state.loading=false;
            state.loadingtxt= "Please Wait";
        },
        ShowSnack:(state, action) => {
            state.snack=true;
            state.snackmsg=action.payload.msg;
            state.snacktime=action.payload.time;
        },
        HideSnack:(state, action) => {
            state.snack=false;
            state.snackmsg='Welcome to FHNLP';
            state.snacktime=3000;
        }
    }
})

export default loginSlice.reducer;
export const {LoginNow, Logout, Showloading, HideLoading, ShowSnack, HideSnack} = loginSlice.actions;