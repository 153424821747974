import React from 'react'

function SalarySlip({salaryslip}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginBottom:'20px' }}>

            <div className='forminput' style={{ width: '100%' }}>
                <h2 style={{ color: "#b47104", textDecoration: "underline" }}>Salary For the month of {salaryslip.month} {salaryslip.year}</h2>
            </div>


            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px', textDecoration:'underline' }}>Employee Information</h4>
            <div className='forminput'>
                <label htmlFor="irla">UID (Employee ID):</label>
                <p>{salaryslip.uid}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Designation:</label>
                <p>{salaryslip.designation}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Name:</label>
                <p>{salaryslip.name}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Department:</label>
                <p>{salaryslip.department}</p>
            </div>

            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px', textDecoration:'underline' }}>Attendance</h4>
            <div className='forminput'>
                <label htmlFor="irla">Working Days:</label>
                <p>{salaryslip.workingdays}</p>
            </div>



            <div className='forminput'>
                <label htmlFor="irla">Leave Allowed:</label>
                <p>{salaryslip.leaveallowed}</p>
            </div>



            <div className='forminput'>
                <label htmlFor="irla">Leave Taken:</label>
                <p>{salaryslip.leavetaken}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Absent:</label>
                <p>{salaryslip.absent}</p>
            </div>



            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px', textDecoration:'underline' }}>Salary Calculation</h4>
            <h5 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>Cost To Compant CTC/Month</h5>
            <div className='forminput'>
                <label htmlFor="irla">Basic Pay:</label>
                <p>{salaryslip.basicpay}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">House Rental Allowance:</label>
                <p>{salaryslip.hra}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Conveyance Allowance:</label>
                <p>{salaryslip.conveyanceallowance}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Medical Allowance:</label>
                <p>{salaryslip.medicalallowance}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Special Allowance:</label>
                <p>{salaryslip.specialallowance}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Additional Perks:</label>
                <p>{salaryslip.additionalperks}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Other Allowance:</label>
                <p>{salaryslip.otherallowances}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Special Efforts:</label>
                <p>{salaryslip.specialefforts}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Gross Salary:</label>
                <p>{salaryslip.grosssalary}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Amount To Bank:</label>
                <p>{salaryslip.amounttobank}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Amount With held if any:</label>
                <p>{salaryslip.amountwithheldifany}</p>
            </div>


            <h5 style={{ width: "100%", color: "#b47104", paddingTop: '10px', textDecoration:'underline' }}>Deduction</h5>
            <div className='forminput'>
                <label htmlFor="irla">Professional Tax:</label>
                <p>{salaryslip.professionaltax}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Other Tax:</label>
                <p>{salaryslip.othertax}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">EPF:</label>
                <p>{salaryslip.epf} </p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Advance Taken:</label>
                <p>{salaryslip.advancetaken}</p>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">Loan EMI:</label>
                <p>{salaryslip.loanemi}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Insurance:</label>
                <p>{salaryslip.insurance}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Leave/ Absent:</label>
                <p>{salaryslip.leave_absent} </p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Total Deductions:</label>
                <p>{salaryslip.totaldeduction}</p>
            </div>

            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px', textDecoration:'underline' }}>Salary Details</h4>


            <div className='forminput'>
                <label htmlFor="irla">Amount Payable:</label>
                <p>{salaryslip.amountpayable} </p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Account Number:</label>
                <p>{salaryslip.accountnumber}</p>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Bank Name:</label>
                <p>{salaryslip.bankname}</p>
            </div>


        </div>


    )
}

export default SalarySlip