import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Decypt, tkn, url } from '../App';
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import Navigation from './Navigation'
import Sidebar from './Sidebar'
import * as XLSX from 'xlsx';

function FormList() {
    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const [list, setlist] = useState(null);



    useEffect(() => {


        dispatches(Showloading("Retrieving Applications, Please wait..."));
        fetch(`${url}cafview/req/formist/`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'usr_ath': window.btoa(tkn)
            },
            body: JSON.stringify({ user: uselect.usr })
        }).then(result => result.json())
            .then(res => {
                res = JSON.parse(Decypt(res));
                // console.log(res);


                if (res.status === 'ok') {
                    // reset
                    setlist(res.msg);

                } else {
                    // console.log(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                }

            }).catch(err => {
                dispatches(ShowSnack({ msg: ' Something went wrong. ' + err, time: 4000 }))
            }).finally(() => {
                dispatches(HideLoading());
            })

        return () => {

        }
    }, [])

    const Downloadxl = () => {
        const worksheet = XLSX.utils.json_to_sheet(list);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet,'Customer Forms');
        XLSX.writeFile(workbook, 'CAF.xlsx');

    }


    return (
        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser' >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between",width:"100%" , alignItems:'center'}}>
                            <h5 style={{textIndent: '20px' }}>List of all Approved Forms</h5>
                            <button onClick={()=> Downloadxl()} className='btn1' style={{height:"35px"}}>Download</button>
                        </div>


                        <div className="usrtbl" style={{ marginBottom: '20px', maxHeight: '90%', marginTop: "-10px" }}>

                            <table>
                                <thead>
                                    <tr>
                                        <th>{`slno`.toUpperCase()}</th>
                                        <th>{`customername`.toUpperCase()}</th>
                                        <th>{`swdo`.toUpperCase()}</th>
                                        <th>{`address`.toUpperCase()}</th>
                                        <th>{`block`.toUpperCase()}</th>
                                        <th>{`tehsil`.toUpperCase()}</th>
                                        <th>{`district`.toUpperCase()}</th>
                                        <th>{`state`.toUpperCase()}</th>
                                        <th>{`pincode`.toUpperCase()}</th>
                                        <th>{`mobile`.toUpperCase()}</th>
                                        <th>{`email`.toUpperCase()}</th>
                                        <th>{`alternatemobile`.toUpperCase()}</th>
                                        <th>{`aadhar`.toUpperCase()}</th>
                                        <th>{`electionid`.toUpperCase()}</th>
                                        <th>{`pangstin`.toUpperCase()}</th>
                                        <th>{`anyothpoipoa`.toUpperCase()}</th>
                                        <th>{`contactperson`.toUpperCase()}</th>
                                        <th>{`pop`.toUpperCase()}</th>
                                        <th>{`zoneidifany`.toUpperCase()}</th>
                                        <th>{`transportmedia`.toUpperCase()}</th>
                                        <th>{`lastmileaccessprovider`.toUpperCase()}</th>
                                        <th>{`typeofcustomer`.toUpperCase()}</th>
                                        <th>{`instllationaddress`.toUpperCase()}</th>
                                        <th>{`udp`.toUpperCase()}</th>
                                        <th>{`mrtg`.toUpperCase()}</th>
                                        <th>{`caf`.toUpperCase()}</th>
                                        <th>{`bandwidthplan`.toUpperCase()}</th>
                                        <th>{`lat`.toUpperCase()}</th>
                                        <th>{`longt`.toUpperCase()}</th>
                                        <th>{`cpeonudistancefromstreet`.toUpperCase()}</th>
                                        <th>{`terminationtype`.toUpperCase()}</th>
                                        <th>{`cpemac`.toUpperCase()}</th>
                                        <th>{`onuontmac`.toUpperCase()}</th>
                                        <th>{`wifiroutermac`.toUpperCase()}</th>
                                        <th>{`agreement`.toUpperCase()}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list === null ?
                                            <>
                                                <tr>
                                                    <td colSpan={8} style={{ color: 'brown', textAlign: 'center' }}> At present there is no Record of CAF Form </td>
                                                </tr>
                                            </>
                                            :
                                            list.map((val, indx) => {
                                                return (
                                                    <tr key={indx}>
                                                        <td>{val.slno}</td>
                                                        <td>{val.customername}</td>
                                                        <td>{val.swdo}</td>
                                                        <td>{val.address}</td>
                                                        <td>{val.block}</td>
                                                        <td>{val.tehsil}</td>
                                                        <td>{val.district}</td>
                                                        <td>{val.state}</td>
                                                        <td>{val.pincode}</td>
                                                        <td>{val.mobile}</td>
                                                        <td>{val.email}</td>
                                                        <td>{val.alternatemobile}</td>
                                                        <td>{val.aadhar}</td>
                                                        <td>{val.electionid}</td>
                                                        <td>{val.pangstin}</td>
                                                        <td>{val.anyothpoipoa}</td>
                                                        <td>{val.contactperson}</td>
                                                        <td>{val.pop}</td>
                                                        <td>{val.zoneidifany}</td>
                                                        <td>{val.transportmedia}</td>
                                                        <td>{val.lastmileaccessprovider}</td>
                                                        <td>{val.typeofcustomer}</td>
                                                        <td>{val.instllationaddress}</td>
                                                        <td>{val.udp}</td>
                                                        <td>{val.mrtg}</td>
                                                        <td>{val.caf}</td>
                                                        <td>{val.bandwidthplan}</td>
                                                        <td>{val.lat}</td>
                                                        <td>{val.longt}</td>
                                                        <td>{val.cpeonudistancefromstreet}</td>
                                                        <td>{val.terminationtype}</td>
                                                        <td>{val.cpemac}</td>
                                                        <td>{val.onuontmac}</td>
                                                        <td>{val.wifiroutermac}</td>
                                                        <td>{val.agreement}</td>
                                                    </tr>
                                                );
                                            })

                                    }


                                </tbody>
                            </table>
                        </div>







                    </div>


                </div>
            </div>




        </>
    )
}

export default FormList