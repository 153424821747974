import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { HideSnack } from '../features/loginSlice';

function Snackmsg() {
    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const [prog, setprog] = useState({ width: '100%', height: '5px' })

    useEffect(() => {

        let timeline = 100;

        const intervalid = setInterval(() => {
            if (timeline > 0) {
                prog.width = `${timeline - 1}%`;
            } else {
                prog.height = '0px';
                prog.width = '0%';
                dispatches(HideSnack());
            }
            setprog({ ...prog });
            timeline--;
        }, uselect.snacktime / 100);

        return () => {
            clearInterval(intervalid);

        }
    }, [])


    return (

        <>
            <div className="snack">
                <div onClick={()=> dispatches(HideSnack())} className="snackclose"> x </div>

                <div className="snackinner">
                    <div className="snackmsg"> {uselect.snackmsg} </div>
                    <div style={{ width: prog.width, height: prog.height }} className="snackprogress"> </div>
                </div>
                

            </div>
        </>
    )
}

export default Snackmsg