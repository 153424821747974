import React, { useState } from 'react'
import NAVIGATION from './../components/assets/icons/navigation.svg'
import Sidebar from './Sidebar'

function Navigation() {
const [display, setdisplay] = useState('none')

function ShowDisplay(){
  display==='none'?setdisplay('flex'):setdisplay('none');
}
  return (
    <>

    <div className='navigationbar'>
      <img onClick={()=> ShowDisplay()} src={NAVIGATION} alt="navigation" className='navimg' />
        <h4 className='navhead'>FASTHOOK NETWORKS PVT LTD</h4>
    </div>

    <div style={{width:'70%', position:'fixed', top:0, left:0, zIndex:5, display:display, backgroundColor:'#fafafa', height:'100vh', overflow:'auto'}}>
      <Sidebar />
    </div>

    <div onClick={()=> ShowDisplay()} style={{width:'100%', position:'fixed', top:0, left:0, bottom:0, right:0, zIndex:4, display:display, backgroundColor:'#c2baba59', height:'100vh', overflow:'auto'}}>
    </div>

    </>
  )
}

export default Navigation