import React from 'react'
import { useState } from 'react'

function OtpModal({ VerifyOtp, CancelOtp, modalmsg ='Enter OTP sent to customer mobile.'}) {
    const [otp, setotp] = useState('');
    return (
        <>
            <div className='modal'>
                <div className='outerscreen'>
                    <div className="innerscreen">
                        <h4>{modalmsg} </h4>
                        <div className="modalinput">
                            <input type="password" placeholder='Enter OTP ' value={otp} onChange={(e) => setotp(e.target.value)} />
                            <div style={{display:'flex', justifyContent:'center', width:'100%', marginTop:'20px'}}>
                                <button className='btn3' onClick={() => CancelOtp()}>Cancel</button>
                                <button className='btn2' onClick={() => VerifyOtp(otp)}>Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtpModal