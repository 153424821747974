import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Decypt, tkn, url } from '../App'
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice'
import CafFormReview from './CafFormReview'
import Navigation from './Navigation'
import Sidebar from './Sidebar'


function ReviewApplication() {
    const uselect = useSelector(state => state.loginSlice);
    const dispatches = useDispatch();
    const location = useLocation()
    const [application, setapplication] = useState(null)


    useEffect(() => {

        dispatches(Showloading("Retrieving Application, Please wait..."));
        fetch(`${url}cafview/req/viewformdetail/`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'usr_ath': window.btoa(tkn)
            },
            body: JSON.stringify({ user: uselect.usr, slno:location.state.slno })
        }).then(result => result.json())
            .then(res => {
                res = JSON.parse(Decypt(res));
                // console.log(res);

                if (res.status === 'ok') {
                    // reset
                    setapplication(res.msg);

                } else {
                    // console.log(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                }

            }).catch(err => {
                dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
            }).finally(() => {
                dispatches(HideLoading());
            })




        return () => {

        }
    }, [])



    return (
        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser' >


                {
                    application===null?
                    <> <div> <h3>Application is not available</h3></div></>
                    :
                    <CafFormReview application={application} verifiedby={location.state.verifiedby} />

                }






                    </div>


                </div>
            </div>




        </>
    )
}

export default ReviewApplication