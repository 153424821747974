import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LOGO from './assets/logo.png'
import HOME from './assets/icons/home.svg'
import LOGOUT from './assets/icons/logout.svg'
import { useDispatch } from 'react-redux'
import { Logout } from '../features/loginSlice'
import { useSelector } from 'react-redux';

function Sidebar() {

    const menu = [
        {
            menuid: '1',
            img: HOME,
            imgalt: 'HOME',
            txt: 'CAF Form',
            linkto: '/dashboard',
            type: 'user'
        },
        {
            menuid: '2',
            img: HOME,
            imgalt: 'Forms',
            txt: 'Form History',
            linkto: '/previousforms',
            type: 'user'
        },
        {
            menuid: '13',
            img: HOME,
            imgalt: 'View Salary',
            txt: 'View Salary',
            linkto: '/viewsalary',
            type: 'user'
        },
        {
            menuid: '14',
            img: HOME,
            imgalt: 'Work Details',
            txt: 'Work Details',
            linkto: '/workdetails',
            type: 'user'
        },

    ]

    const menuadmin = [
        {
            menuid: '5',
            img: HOME,
            imgalt: 'FormList',
            txt: 'Form List',
            linkto: '/formlist'
        },
      
        {
            menuid: '3',
            img: HOME,
            imgalt: 'HOME',
            txt: 'Applications',
            linkto: '/admindashboard'
        },
        {
            menuid: '4',
            img: HOME,
            imgalt: 'HOME',
            txt: 'Employees',
            linkto: '/users'
        },
         {
            menuid: '15',
            img: HOME,
            imgalt: 'Salary',
            txt: 'Salary Details',
            linkto: '/salarydetails'
        },
        {
            menuid: '16',
            img: HOME,
            imgalt: 'Salary',
            txt: 'Work Done',
            linkto: '/workdone'
        },

    ]


    const dispatches = useDispatch();
    const loginslice = useSelector(state => state.loginSlice);
    const navigate = useNavigate();
    useEffect(() => {
        if (loginslice.logggedIn !== true) {
            navigate('/');
        }

        return () => {
        }
    }, [loginslice, navigate])

    function LogoutUsr() {
        dispatches(Logout());

    }


    return (
        <>
            <div className='sidebardiv'>
                <img src={LOGO} alt="BSF LOGO" className='logoimg' />

                <div className='sidebarmenu'>

                    {
                        menu.map((val, indx) => {
                            return (
                                loginslice.usertype==='admin' && (val.menuid=='13' || val.menuid==='14')?<div key={indx}></div>:
                                <Link key={indx} to={val.linkto}>
                                    <div className='menuitem'>
                                        <img src={val.img} alt={val.imgalt} className='icon' />
                                        <h4> {val.txt} </h4>
                                    </div>
                                </Link>
                            )

                        })
                    }

                    {
                        loginslice.usertype==='admin'?
                        menuadmin.map((val, indx) => {
                            return (
                                <Link key={indx} to={val.linkto}>
                                    <div className='menuitem'>
                                        <img src={val.img} alt={val.imgalt} className='icon' />
                                        <h4> {val.txt} </h4>
                                    </div>
                                </Link>
                            )

                        }):<></>
                    }

                    <div onClick={() => LogoutUsr()} className='menuitem menuitemlogout'>
                        <img src={LOGOUT} alt='Logout' className='icon' />
                        <h4> Logout </h4>
                    </div>



                </div>

            </div>
        </>

    )
}

export default Sidebar