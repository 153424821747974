import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Decypt, tkn, url } from '../App';
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import OtpModal from './OtpModal';
import VerifyModal from './VerifyModal';

function CafForm() {
    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const [optmodal, setoptmodal] = useState(false)
    const [optmodal2, setoptmodal2] = useState(false)
    const [verifymodal, setverifymodal] = useState(false)
    const [msg2, setmsg2] = useState('');
    const [modalmsg, setmodalmsg] = useState('');
    const date = new Date();

    const [customer, setCustomer] = useState({
        slno: 'FHNPL_' + date.getUTCFullYear() + '_' + date.getUTCMonth() + '_' + date.getDay() + date.getHours() + date.getMinutes() + date
            .getSeconds() + date.getUTCMilliseconds(),
        customername: '',
        swdo: '',
        address: '',
        block: '',
        tehsil: '',
        district: '',
        state: 'Jammu and Kashmir',
        pincode: '',
        mobile: '',
        email: '',
        alternatemobile: '',
        aadhar: '',
        electionid: '',
        pangstin: '',
        anyothpoipoa: '',
        contactperson: '',
        pop: '',
        zoneidifany: '',
        transportmedia: '',
        lastmileaccessprovider: '',
        typeofcustomer: '',
        instllationaddress: '',
        udp: 'no',
        mrtg: 'no',
        caf: 'no',
        bandwidthplan: '',
        lat: '',
        longt: '',
        cpeonudistancefromstreet: '',
        terminationtype: '',
        cpemac: '',
        onuontmac: '',
        wifiroutermac: '',
        agreement: false
    })


    const [imgval, setimgval] = useState({
        usrimg: null,
        idproff1front: null,
        idproff1back: null,
        idproff2front: null,
        idproff2back: null
    });

    useEffect(() => {
        HideSnackIf();

        return () => {
        }
    }, [])


    function HideSnackIf() {
        if (uselect.snack === true) {
            dispatches(HideSnack())
        }
    }

    function ResetForm() {
        const date = new Date();
        let cust = {
            slno: 'FHNPL_' + date.getUTCFullYear() + '_' + date.getUTCMonth() + '_' + date.getDay() + date.getHours() + date.getMinutes() + date
                .getSeconds() + date.getUTCMilliseconds(),
            customername: '',
            swdo: '',
            address: '',
            block: '',
            tehsil: '',
            district: '',
            state: 'Jammu and Kashmir',
            pincode: '',
            mobile: '',
            email: '',
            alternatemobile: '',
            aadhar: '',
            electionid: '',
            pangstin: '',
            anyothpoipoa: '',
            contactperson: '',
            pop: '',
            zoneidifany: '',
            transportmedia: '',
            lastmileaccessprovider: '',
            typeofcustomer: '',
            instllationaddress: '',
            udp: 'no',
            mrtg: 'no',
            caf: 'no',
            bandwidthplan: '',
            lat: '',
            longt: '',
            cpeonudistancefromstreet: '',
            terminationtype: '',
            cpemac: '',
            onuontmac: '',
            wifiroutermac: '',
            agreement: false
        }

        const imglist = {
            usrimg: null,
            idproff1front: null,
            idproff1back: null,
            idproff2front: null,
            idproff2back: null
        }

        setCustomer({ ...cust });
        setimgval({ ...imglist });


    }
    function CustomerForm() {


        if (imgval.usrimg === null) {
            dispatches(ShowSnack({ msg: 'Image of applicant is required', time: 4000 }));
        } else if (imgval.idproff1front === null) {
            dispatches(ShowSnack({ msg: 'Aadhar front image of applicant is required', time: 4000 }))
        } else if (imgval.idproff1back === null) {
            dispatches(ShowSnack({ msg: 'Aadhar back image of applicant is required', time: 4000 }))
        } else if (imgval.idproff2front === null) {
            dispatches(ShowSnack({ msg: 'Second Id proof front image of applicant is required', time: 4000 }))
        } else if (imgval.idproff2back === null) {
            dispatches(ShowSnack({ msg: 'Second Id proof back image of applicant is required', time: 4000 }))
        } else {
            if (customer.cpemac === '') {
                customer.cpemac = '-';
                setCustomer({ ...customer });
            }
            if (customer.wifiroutermac === '') {
                customer.wifiroutermac = '-';
                setCustomer({ ...customer });
            }
            if (customer.onuontmac === '') {
                customer.onuontmac = '-';
                setCustomer({ ...customer });
            }

            HideSnackIf();
            dispatches(Showloading('Form submission in progress...'));
            fetch(`${url}caf/req/cafform/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ customer, user: uselect.usr })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // setoptmodal(true);
                        dispatches(Showloading('Please wait while we are uploading your images...'));
                        UploadImage();

                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 5000 }))
                        dispatches(HideLoading());
                        // console.log(res.msg);
                    }

                }).catch(err => {
                    // console.log(err);
                    dispatches(HideLoading());
                    dispatches(ShowSnack({ msg: 'Something went wrong.', time: 5000 }))
                })

        }



    }


    function UploadImage() {
        if (imgval.usrimg === null) {
            dispatches(ShowSnack({ msg: 'Image of applicant is required', time: 4000 }));
        } else if (imgval.idproff1front === null) {
            dispatches(ShowSnack({ msg: 'Aadhar front image of applicant is required', time: 4000 }))
        } else if (imgval.idproff1back === null) {
            dispatches(ShowSnack({ msg: 'Aadhar back image of applicant is required', time: 4000 }))
        } else if (imgval.idproff2front === null) {
            dispatches(ShowSnack({ msg: 'Second Id proof front image of applicant is required', time: 4000 }))
        } else if (imgval.idproff2back === null) {
            dispatches(ShowSnack({ msg: 'Second Id proof back image of applicant is required', time: 4000 }))
        } else {
            const formdata = new FormData();
            formdata.append('img', imgval.usrimg);
            formdata.append('idproff1front', imgval.idproff1front);
            formdata.append('idproff1back', imgval.idproff1back);
            formdata.append('idproff2front', imgval.idproff2front);
            formdata.append('idproff2back', imgval.idproff2back);
            formdata.append('slno', customer.slno);
            formdata.append('usr', uselect.usr);
            formdata.append('mobile', customer.mobile);
            formdata.append('customername', customer.customername);

            // for (const value of formdata.values()) {
            //     console.log(value)
            // }

            fetch(`${url}caf/req/uploadimages/`, {
                method: "post",
                headers: {
                    'usr_ath': window.btoa(tkn)
                },
                body: formdata
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        setmsg2(res.msg2);
                        setmodalmsg(res.msg);
                        setoptmodal(true);
                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                        // console.log(res.msg);
                    }

                }).catch(err => {
                    // console.log(err);
                    dispatches(ShowSnack({ msg: 'Something went wrong.', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })



        }
    }

    function VerifyOtp(otp) {
        if (otp.length !== 6) {
            dispatches(ShowSnack({ msg: ' OTP entered is invalid ', time: 4000 }))
        } else if (msg2 === '') {
            dispatches(ShowSnack({ msg: ' Invalid request found', time: 4000 }))
        } else if (customer.slno === '' || uselect.usr === '') {
            dispatches(ShowSnack({ msg: ' Application id is not valid ', time: 4000 }))
        } else {
            dispatches(Showloading('OTP verification is under progress...'))

            fetch(`${url}caf/req/cafformverify/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ otp, user: uselect.usr, slno: customer.slno, msg2 })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        // ResetForm();
                        setoptmodal(false);
                        setverifymodal(true);
                        setmsg2('');
                        dispatches(ShowSnack({ msg: res.msg, time: 6000 }))
                    } else {
                        console.log(res.msg);
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })



        }


    }

    function VerifyOtp2(otp) {
        // console.log(otp);
        if (otp.length !== 6) {
            dispatches(ShowSnack({ msg: ' OTP entered is invalid ', time: 4000 }))
        } else if (msg2 === '') {
            dispatches(ShowSnack({ msg: ' Invalid request found', time: 4000 }))
        } else if (customer.slno === '' || uselect.usr === '') {
            dispatches(ShowSnack({ msg: ' Application id is not valid ', time: 4000 }))
        } else {
            dispatches(Showloading('OTP verification is under progress...'))

            fetch(`${url}caf/req/cafformverify2/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ otp, user: uselect.usr, slno: customer.slno, msg2 })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        ResetForm();
                        setoptmodal2(false);
                        setmodalmsg('');
                        setmsg2('');
                        dispatches(ShowSnack({ msg: res.msg, time: 7000 }))
                    } else {
                        // console.log(res.msg);
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })



        }


    }

    function SendEngineerOtp(){

        dispatches(Showloading('Sending OTP to Field Engineer...'))

        fetch(`${url}caf/req/cafformverifyengg/`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'usr_ath': window.btoa(tkn)
            },
            body: JSON.stringify({ user: uselect.usr, slno: customer.slno })
        }).then(result => result.json())
            .then(res => {
                res = JSON.parse(Decypt(res));
                // console.log(res);

                if (res.status === 'ok') {
                    // reset
                    setverifymodal(false);
                    setoptmodal2(true);
                    setmsg2(res.msg2);
                    setmodalmsg(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 7000 }))
                } else {
                    // console.log(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                }

            }).catch(err => {
                dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
            }).finally(() => {
                dispatches(HideLoading());
            })

    }

    function CancelOtp() {
        ResetForm();
        setoptmodal(false)
    }

    function CancelOtp2() {
        setoptmodal2(false)
    }
    return (
        <React.Fragment>
            {
                optmodal === true ? <OtpModal VerifyOtp={(otp) => VerifyOtp(otp)} CancelOtp={() => CancelOtp()} modalmsg={modalmsg} /> : <></>
            }

            {
                optmodal2 === true ? <OtpModal VerifyOtp={(otp) => VerifyOtp2(otp)} CancelOtp={() => CancelOtp2()} modalmsg={modalmsg} /> : <></>
            }

            {
                verifymodal === true ? <VerifyModal ShowOTPModal={() => { SendEngineerOtp() }} /> : <></>
            }


            <div style={{ width: '100%', padding: '20px' }}>
                <h4 style={{ padding: '20px', textDecoration: '2px solid green underline', textAlign: 'center' }}>CUSTOMER APPLICATION FORM (CAF) INTERNET SERVICES</h4>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">APPLICATION SERIAL NO.: </label>
                <input type="text" value={customer.slno} onChange={(e) => { customer.slno = e.target.value; setCustomer({ ...customer }) }} placeholder='APPLICATION SERIAL NO.' className='inputtxt1' autoComplete='off' disabled={true} />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">NAME OF CUSTOMER : </label>
                <input type="text" autoFocus={true} value={customer.customername} onChange={(e) => { customer.customername = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='NAME OF CUSTOMER' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">S/O, W/O, D/O : </label>
                <input type="text" value={customer.swdo} onChange={(e) => { customer.swdo = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='S/O, W/O, D/O ' className='inputtxt1' autoComplete='off' />
            </div>


            <div className='forminput'>
                <label htmlFor="irla">ADDRESS : </label>
                <input type="text" value={customer.address} onChange={(e) => { customer.address = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='ADDRESS ' className='inputtxt1' autoComplete='off' />
            </div>


            <div className='forminput'>
                <label htmlFor="irla">BLOCK : </label>
                <input type="text" value={customer.block} onChange={(e) => { customer.block = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='BLOCK ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">TEHSIL : </label>
                <input type="text" value={customer.tehsil} onChange={(e) => { customer.tehsil = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='TEHSIL ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">DISTRICT : </label>
                <input type="text" value={customer.district} onChange={(e) => { customer.district = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='DISTRICT ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">STATE/UT : </label>
                <input type="text" value={customer.state} onChange={(e) => { customer.state = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='J&K ' className='inputtxt1' autoComplete='off' disabled={true} />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">PIN CODE : </label>
                <input type="number" value={customer.pincode} onChange={(e) => { customer.pincode = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='PIN CODE ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">MOBILE NO.: </label>
                <input type="number" value={customer.mobile} onChange={(e) => { customer.mobile = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='MOBILE NO. ' className='inputtxt1' autoComplete='off' />
            </div>



            <div className='forminput'>
                <label htmlFor="irla">EMAIL ID: </label>
                <input type="text" value={customer.email} onChange={(e) => { customer.email = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='EMAIL ID ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ALTERNATE MOBILE NO.: </label>
                <input type="number" value={customer.alternatemobile} onChange={(e) => { customer.alternatemobile = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='ALTERNATE MOBILE NO. ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">AADHAR NO.: </label>
                <input type="number" value={customer.aadhar} onChange={(e) => { customer.aadhar = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='AADHAR NO. ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ELECTION ID NO.: </label>
                <input type="text" value={customer.electionid} onChange={(e) => { customer.electionid = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='ELECTION ID NO. ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">PAN/GSTIN: </label>
                <input type="text" value={customer.pangstin} onChange={(e) => { customer.pangstin = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='PAN/GSTIN ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ANY OTHER POI/POA: </label>
                <input type="text" value={customer.anyothpoipoa} onChange={(e) => { customer.anyothpoipoa = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='ANY OTHER POI/POA ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">CONTACT PERSON: </label>
                <input type="text" value={customer.contactperson} onChange={(e) => { customer.contactperson = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='CONTACT PERSON ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">PoP: </label>
                <select className='inputtxt1' value={customer.pop} onChange={(e) => { customer.pop = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="">Select</option>
                    <option value="SHOPIAN">SHOPIAN</option>
                    <option value="ANANTNAG">ANANTNAG</option>
                    <option value="PULWAMA">PULWAMA</option>
                    <option value="AWANTIPORA">AWANTIPORA</option>
                    <option value="QAZIGUND">QAZIGUND</option>
                    <option value="PAHALGAM">PAHALGAM</option>
                    <option value="SONMARAG">SONMARAG</option>
                    <option value="SRINAGAR">SRINAGAR</option>
                    <option value="PATTAN">PATTAN</option>
                    <option value="SOPORE">SOPORE</option>
                    <option value="BARAMULLA">BARAMULLA</option>
                    <option value="BANDIPORA 1">BANDIPORA 1</option>
                    <option value="BANDIPORA 2">BANDIPORA 2</option>

                </select>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ZONE ID IF ANY: </label>
                {/* <input type="text" value={customer.zoneidifany} onChange={(e) => { customer.zoneidifany = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='ZONE ID  ' className='inputtxt1' autoComplete='off' /> */}
                <select className='inputtxt1' value={customer.zoneidifany} onChange={(e) => { customer.zoneidifany = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="">Select</option>
                    <option value="OLT NOWGAM">OLT NOWGAM</option>
                    <option value="OLT SHOPIAN">OLT SHOPIAN</option>
                    <option value="OLT HAJIPORA">OLT HAJIPORA</option>
                    <option value="OLT BEHBAGH">OLT BEHBAGH</option>
                    <option value="OLT KHANABAL">OLT KHANABAL</option>
                    <option value="OLT AWANTIPORA">OLT AWANTIPORA</option>
                    <option value="OLT BEMINA">OLT BEMINA</option>
                    <option value="OLT SOPORE">OLT SOPORE</option>
                    <option value="OLT NADIHAL">OLT NADIHAL</option>
                </select>

            </div>

            <div className='forminput'>
                <label htmlFor="irla">Transport Media: </label>
                <select className='inputtxt1' value={customer.transportmedia} onChange={(e) => { customer.transportmedia = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="">Select</option>
                    <option value="OFC">OFC</option>
                    <option value="RADIO">RADIO</option>
                    <option value="SATELLITE">SATELLITE</option>
                    <option value="DSL">DSL</option>
                    <option value="CAT5/6">CAT5/6</option>
                    <option value="COAXIAL CABLE">COAXIAL CABLE</option>
                </select>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Last mile access provider: </label>
                <select className='inputtxt1' value={customer.lastmileaccessprovider} onChange={(e) => { customer.lastmileaccessprovider = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="">Select</option>
                    <option value="ATIPL">ATIPL</option>
                    <option value="FHNPL">FHNPL</option>
                    <option value="BHARTI AIRTEL">BHARTI AIRTEL</option>
                    <option value="INDUS">INDUS</option>
                    <option value="ATC">ATC</option>
                    <option value="GTLg">GTLg</option>
                    <option value="TOWERVISION">TOWERVISION</option>
                    <option value="VIL">VIL</option>
                    <option value="JIO">JIO</option>
                    <option value="LCO">LCO</option>
                    <option value="OTHER">OTHER</option>

                </select>

            </div>

            <div className='forminput'>
                <label htmlFor="irla">TYPE OF CUSTOMER: </label>
                <select className='inputtxt1' value={customer.typeofcustomer} onChange={(e) => { customer.typeofcustomer = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="">Select</option>
                    <option value="CORPORATE">CORPORATE</option>
                    <option value="OSP">OSP</option>
                    <option value="HOME USER">HOME USER</option>
                    <option value="GOVERNMENT">GOVERNMENT</option>
                </select>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">INSTALLATION ADDRESS WITH LANDMARK : </label>
                <textarea value={customer.instllationaddress} onChange={(e) => { customer.instllationaddress = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='INSTALLATION ADDRESS' className='inputtxt1' autoComplete='off' cols="30" rows="5"></textarea>
            </div>

            <div className='forminput'>

            </div>



            <div className='forminput' style={{ width: '98%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>


                <h3 style={{ width: '100%' }}>Availability of :- </h3> <br />
                <div className='forminput checkinput' style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <label htmlFor="irla">a) UDP: </label>
                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.udp === 'no' ? false : true} onChange={() => { customer.udp === 'no' ? customer.udp = 'yes' : customer.udp = 'no'; setCustomer({ ...customer }) }} name="udp" id="udp" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="udp">Yes</label>
                    </div>

                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.udp === 'yes' ? false : true} onChange={() => { customer.udp === 'no' ? customer.udp = 'yes' : customer.udp = 'no'; setCustomer({ ...customer }) }} name="udpno" id="udpno" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="udpno">No</label>
                    </div>

                </div>

                <div className='forminput checkinput' style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <label htmlFor="irla">b) MRTG: </label>
                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.mrtg === 'no' ? false : true} onChange={() => { customer.mrtg === 'no' ? customer.mrtg = 'yes' : customer.mrtg = 'no'; setCustomer({ ...customer }) }} name="MRTG" id="MRTG" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="MRTG">Yes</label>
                    </div>

                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.mrtg === 'yes' ? false : true} onChange={() => { customer.mrtg === 'no' ? customer.mrtg = 'yes' : customer.mrtg = 'no'; setCustomer({ ...customer }) }} name="MRTGNO" id="MRTGNO" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="MRTGNO">No</label>
                    </div>

                </div>


                <div className='forminput checkinput' style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <label htmlFor="irla">c) CAF: </label>
                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.caf === 'no' ? false : true} onChange={() => { customer.caf === 'no' ? customer.caf = 'yes' : customer.caf = 'no'; setCustomer({ ...customer }) }} name="CAF" id="CAF" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="CAF">Yes</label>
                    </div>

                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.caf === 'yes' ? false : true} onChange={() => { customer.caf === 'no' ? customer.caf = 'yes' : customer.caf = 'no'; setCustomer({ ...customer }) }} name="CAFNO" id="CAFNO" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="CAFNO">No</label>
                    </div>

                </div>




            </div>


            <div className='forminput'>
                <label htmlFor="irla">BANDWIDTH/PLAN: </label>
                {/* <input type="text" value={customer.bandwidthplan} onChange={(e) => { customer.bandwidthplan = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='BANDWIDTH/PLAN ' className='inputtxt1' autoComplete='off' /> */}
                <select className='inputtxt1' value={customer.bandwidthplan} onChange={(e) => { customer.bandwidthplan = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="">Select</option>
                    <option value="FPD040_Internet">FPD040_Internet</option>
                    <option value="FPD050_Internet">FPD050_Internet</option>
                    <option value="FPD060_Internet">FPD060_Internet</option>
                    <option value="FPD100_Internet">FPD100_Internet</option>
                    <option value="FPD002">FPD002</option>
                    <option value="FPD004">FPD004</option>
                    <option value="FPD006">FPD006</option>
                    <option value="FPD008">FPD008</option>
                    <option value="FPD010">FPD010</option>
                    <option value="FPD015">FPD015</option>
                    <option value="FPD020">FPD020</option>
                    <option value="2 Mbps Prime UL">2 Mbps Prime UL</option>
                    <option value="4 Mbps Prime UL">4 Mbps Prime UL</option>
                    <option value="10 Mbps Prime UL">10 Mbps Prime UL</option>
                    <option value="16 Mbps Prime UL">16 Mbps Prime UL</option>
                    <option value="20 Mbps Prime UL">20 Mbps Prime UL</option>
                    <option value="8 Mbps Business Internet">8 Mbps Business Internet</option>
                    <option value="30 Mbps Business Internet">30 Mbps Business Internet</option>
                    <option value="40 Mbps Business Internet">40 Mbps Business Internet</option>
                    <option value="50 Mbps Business Internet">50 Mbps Business Internet</option>
                    <option value="80 Mbps Business Internet">80 Mbps Business Internet</option>

                </select>
            </div>

            <div className='forminput'>
                <label htmlFor="irla">CUSTOMER BUILDING LAT: </label>
                <input type="text" value={customer.lat} onChange={(e) => { customer.lat = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='LATITUDE ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">CUSTOMER BUILDING LONG: </label>
                <input type="text" value={customer.longt} onChange={(e) => { customer.longt = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='LONGITUDE ' className='inputtxt1' autoComplete='off' />
            </div>



            {
                customer.transportmedia === '' ? <></> :

                    <>
                        <div className='forminput'>
                            <label htmlFor="irla">CPE/ ONU DISTANCE FROM STREET POLE / BTS: </label>
                            <input type="text" value={customer.cpeonudistancefromstreet} onChange={(e) => { customer.cpeonudistancefromstreet = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='CPE/ ONU DISTANCE FROM STREET POLE / BTS ' className='inputtxt1' autoComplete='off' />
                        </div>

                        <div className='forminput'>
                            <label htmlFor="irla">TERMINATION TYPE: OFC/ CAT 6 LENGTH IN METERS: </label>
                            {/* <input type="text" value={customer.terminationtype} onChange={(e) => { customer.terminationtype = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='TERMINATION TYPE ' className='inputtxt1' autoComplete='off' /> */}
                            <select className='inputtxt1' value={customer.terminationtype} onChange={(e) => { customer.terminationtype = e.target.value; setCustomer({ ...customer }) }}>
                                <option value="">Select</option>

                                {
                                    customer.transportmedia === 'OFC' ?
                                        <>
                                            <option value="OFC PATCH CORD WITH  INDOOR JC BOX">OFC PATCH CORD WITH  INDOOR JC BOX</option>
                                            <option value="OFC PATCH CORD WITH WITH OUTDOOR JC BOX">OFC PATCH CORD WITH WITH OUTDOOR JC BOX</option>
                                        </> :
                                        <>
                                            <option value="20 Mtrs CAT 6 Patch Cord ">20 Mtrs CAT 6 Patch Cord </option>
                                            <option value="30 Mtrs  CAT 6 Patch Cord ">30 Mtrs  CAT 6 Patch Cord </option>
                                            <option value="40 Mtrs  CAT 6 Patch Cord ">40 Mtrs  CAT 6 Patch Cord </option>
                                        </>
                                }


                            </select>
                        </div>


                        {
                            customer.transportmedia === 'OFC' ? <></> :
                                <div className='forminput'>
                                    <label htmlFor="irla">CPE MAC: </label>
                                    <input type="text" value={customer.cpemac} onChange={(e) => { customer.cpemac = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='CPE MAC ' className='inputtxt1' autoComplete='off' />
                                </div>
                        }

                        {
                            customer.transportmedia === 'OFC' ?
                                <div className='forminput'>
                                    <label htmlFor="irla">XPON/ONU/ONT MAC: </label>
                                    <input type="text" value={customer.onuontmac} onChange={(e) => { customer.onuontmac = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='ONU/ONT MAC ' className='inputtxt1' autoComplete='off' />
                                </div> : <></>
                        }

                        {
                            customer.transportmedia === 'OFC' ? <></> :
                                <div className='forminput'>
                                    <label htmlFor="irla">WI-FI ROUTER MAC: </label>
                                    <input type="text" value={customer.wifiroutermac} onChange={(e) => { customer.wifiroutermac = e.target.value.toUpperCase(); setCustomer({ ...customer }) }} placeholder='WI-FI ROUTER MAC' className='inputtxt1' autoComplete='off' />
                                </div>
                        }

                        {
                            customer.transportmedia !== 'OFC' ? <div className='forminput hideforminput'> {/* Blank  */}     </div> : <></>
                        }


                    </>
            }
            {
                customer.transportmedia === 'OFC' ? <></> :
                customer.transportmedia === ''?<></>:
                    <div className='forminput hideforminput'> {/* Blank  */}     </div>
            }

            <div className='forminput'>
                <div style={{ width: '92%' }}>
                    <label htmlFor="irla">Aadhar Card Front: </label>
                    <input type="file" placeholder='upload Image' onChange={(e) => {
                        if (e.target.files[0].size > 204800) {
                            alert('Image size should not be greater than 200kb');
                            imgval.idproff1front = null;
                            setimgval({ ...imgval })
                        } else {
                            imgval.idproff1front = e.target.files[0];
                            setimgval({ ...imgval });
                        }

                        // console.log(imgval)
                    }} className='inputtxt1' />
                </div>
                <div style={{ width: '92%' }}>
                    <div style={{ maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        {
                            imgval.idproff1front === null ? <></> :
                                <>
                                    <img src={URL.createObjectURL(imgval.idproff1front)} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                                    <p style={{ fontSize: '10px' }}>Aadhar Front</p>
                                </>
                        }
                    </div>
                </div>

            </div>




            <div className='forminput'>
                <div style={{ width: '92%' }}>
                    <label htmlFor="irla">Aadhar Card Back: </label>
                    <input type="file" placeholder='upload Image' onChange={(e) => {
                        if (e.target.files[0].size > 204800) {
                            alert('Image size should not be greater than 200kb');
                            imgval.idproff1back = null;
                            setimgval({ ...imgval })
                        } else {
                            imgval.idproff1back = e.target.files[0];
                            setimgval({ ...imgval });
                        }

                        // console.log(imgval)
                    }} className='inputtxt1' />
                </div>
                <div style={{ width: '92%' }}>
                    <div style={{ maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        {
                            imgval.idproff1back === null ? <></> :
                                <>
                                    <img src={URL.createObjectURL(imgval.idproff1back)} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                                    <p style={{ fontSize: '10px' }}>Aadhar Back</p>
                                </>
                        }
                    </div>
                </div>

            </div>


            <div className='forminput'>
                <div style={{ width: '92%' }}>
                    <label htmlFor="irla">Other ID Front: </label>
                    <input type="file" placeholder='upload Image' onChange={(e) => {
                        if (e.target.files[0].size > 204800) {
                            alert('Image size should not be greater than 200kb');
                            imgval.idproff2front = null;
                            setimgval({ ...imgval })
                        } else {
                            imgval.idproff2front = e.target.files[0];
                            setimgval({ ...imgval });
                        }

                        // console.log(imgval)
                    }} className='inputtxt1' />
                </div>
                <div style={{ width: '92%' }}>
                    <div style={{ maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        {
                            imgval.idproff2front === null ? <></> :
                                <>
                                    <img src={URL.createObjectURL(imgval.idproff2front)} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                                    <p style={{ fontSize: '10px' }}>Other ID Front</p>
                                </>
                        }
                    </div>
                </div>

            </div>



            <div className='forminput'>
                <div style={{ width: '92%' }}>
                    <label htmlFor="irla">Other ID back: </label>
                    <input type="file" placeholder='upload Image' onChange={(e) => {
                        if (e.target.files[0].size > 204800) {
                            alert('Image size should not be greater than 200kb');
                            imgval.idproff2back = null;
                            setimgval({ ...imgval })
                        } else {
                            imgval.idproff2back = e.target.files[0];
                            setimgval({ ...imgval });
                        }

                        // console.log(imgval)
                    }} className='inputtxt1' />
                </div>
                <div style={{ width: '92%' }}>
                    <div style={{ maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        {
                            imgval.idproff2back === null ? <></> :
                                <>
                                    <img src={URL.createObjectURL(imgval.idproff2back)} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                                    <p style={{ fontSize: '10px' }}>Other ID back</p>
                                </>
                        }
                    </div>
                </div>

            </div>


            <div className='forminput'>
                <div style={{ width: '92%' }}>
                    <label htmlFor="irla">Upload Image: </label>
                    <input type="file" placeholder='upload Image' onChange={(e) => {
                        if (e.target.files[0].size > 204800) {
                            alert('Image size should not be greater than 200kb');
                            imgval.usrimg = null;
                            setimgval({ ...imgval })
                        } else {
                            imgval.usrimg = e.target.files[0];
                            setimgval({ ...imgval });
                        }

                        // console.log(imgval)
                    }} className='inputtxt1' />
                </div>
                <div style={{ width: '92%' }}>
                    <div style={{ width: '120px', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        {
                            imgval.usrimg === null ? <></> :
                                <>
                                    <img src={URL.createObjectURL(imgval.usrimg)} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                                    <p style={{ fontSize: '10px' }}>Image Preview</p>
                                </>
                        }
                    </div>
                </div>

            </div>



            <div className='forminput'> {/* Blank  */}     </div>

            <div className='forminput' style={{ flexDirection: 'row', width: '98%' }}>
                <input type="checkbox" checked={customer.agreement} onChange={(e) => { customer.agreement = !(customer.agreement); setCustomer({ ...customer }) }} name="agreement" id="agreement" style={{ marginTop: '15px' }} />
                <label htmlFor="agreement" style={{ fontSize: '12px' }}>
                    I hereby declare that I have read and understood all the terms & conditions as mentioned below / overleaf / at
                    https://fhnpl.com  and hereby agree to all these. I also declare that information provided by me above is true
                    and correct, if found wrong / fake at any later stage, I will be fully responsible for the same. . I declare
                    and certify that I / we will be fully responsible for the usage, management, physical / technical security of
                    the CPE installed for the internet services. I/we certify that from allotted IP there will be no anti-national
                    activities via social networking, proxies, VPN, VPs , encrypted files containing any sort of video/photo/media
                    file / document uploaded to any web platform or messaging service . I/we will provide complete access to all IT
                    & ITeS content and infrastructure as and when required by any government authority / security agencies. I/we
                    further declare that I/we will honour all the bills raised by FHNPL for the services availed by me / us / we .
                    I/we are not undertaking the following activities which are illegal and violate the terms of agreement with
                    FHNPL: a) Terminating Internet telephony calls to any public telephone number (PSTN/ISDN/PLMN etc.) in India,
                    irrespective of whether the same originates in India or abroad [i.e. establishing connection between Internet
                    / ILL and PRI/SIPT/GSM Gateway/Mobile SIM for Voice communication from anywhere to anywhere by means of
                    dialling a telephone number (PSTN/ ISDN/ PLMN)].b) Deploying Wifi on my/our own without centralized
                    authentication or without password protection’s) c) Undertake to comply with all applicable laws and
                    regulations, including without limitation, the provisions of the Indian Telegraph Rules, 1951 made
                    thereunder, Indian Wireless Act 1933, IT Act 2000 and TRAI Act 1997 and any amendments or replacements made
                    thereto from time to time .d) I/We shall not re-sell the services procured from FHNPL to any third party or
                    any other person. e) I / We Ensure that objectionable, obscene, unauthorized or any other content, messages
                    or communications infringing copyright, Intellectual property right and international & domestic cyber laws,
                    in any form or inconsistent with the laws of India, are not carried out by us .f)I certify that minimum
                    contract period of the service will be 1 Year and in case of foreclosure by me/we/us we will pay full 1
                    year (Annual) service costs towards FHNPL.
                </label>
            </div>


            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <button onClick={() => CustomerForm()} className='btn1'> Submit </button>
            </div>


        </React.Fragment>
    )
}

export default CafForm