import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Decypt, tkn, url } from '../App'
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice'
import Footer from './Footer'
import Navigation from './Navigation'
import Sidebar from './Sidebar'


function WorkDetails() {
    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const [input, setinput] = useState('')
    const [worklist, setworklist] = useState(null);
    const [refreshlist, setrefreshlist] = useState(false)

    useEffect(() => {

        dispatches(Showloading("Retrieving Users, Please wait..."));
        fetch(`${url}userview/req/worklist/`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'usr_ath': window.btoa(tkn)
            },
            body: JSON.stringify({ user: uselect.usr })
        }).then(result => result.json())
            .then(res => {
                res = JSON.parse(Decypt(res));
                // console.log(res);

                if (res.status === 'ok') {
                    // reset
                    setworklist(res.msg);


                } else {
                    // console.log(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                }

            }).catch(err => {
                dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
            }).finally(() => {
                dispatches(HideLoading());
            })

        return () => {

        }
    }, [refreshlist])

    const AddWork = () => {
        if (input === '') {
            dispatches(ShowSnack({ msg: 'Please Enter work details first. ', time: 4000 }))
        } else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/addwork/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr , input})
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setrefreshlist(!refreshlist);
                       setinput('');
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }

    }


    return (
        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser1'>


                        <div style={{ width: '100%' }}>
                            <h4 style={{ textDecoration: '2px solid green underline', textAlign: 'center' }}>Add Work Details</h4>
                        </div>

                        <div className='forminput' style={{ width: "95%" }}>
                            <label htmlFor="irla">Today's Work Detail: </label>
                            <textarea type="text" className='inputtxt1' value={input} onChange={(e) => { setinput(e.target.value) }} cols="30" rows="10"></textarea>

                        </div>

                        <div className='forminput' style={{ width: "95%" }}>
                            <button onClick={AddWork} className='btn1'>Submit</button>
                        </div>



                        <div style={{ width: '100%', paddingTop: '30px', paddingBottom: "20px" }}>
                            <h4 style={{ padding: '20px', textDecoration: '2px solid green underline' }}>List of Users</h4>
                        </div>

                        <div className="usrtbl" style={{ marginBottom: '30px' }}>
                            <table>
                                <thead>
                                    <tr>
                                        {/* <th>Date</th> */}
                                        <th>Task performed By You</th>
                                        <th>Status & Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        worklist === null ?
                                            <>
                                                <tr>
                                                    <td colSpan={8} style={{ color: 'brown', textAlign: 'center' }}> At present there is no user </td>
                                                </tr>
                                            </>
                                            :
                                            worklist.map((val, indx) => {
                                                return (
                                                    <tr key={indx}>
                                                        {/* <td>{val.workcreatedon} </td> */}
                                                        <td><b>On {val.workcreatedon.substring(0,10)}</b>  <br />{val.workdescription }</td>
                                                        <td>
                                                        <p style={val.workstatus==='Rejected'?{color:"brown"}:val.workstatus==='Approved'?{color:"green"}:{}}> <b>{val.workstatus}</b> </p> 
                                                            {val.adminreply}
                                                        </td>
                                                       
                                                    </tr>
                                                );
                                            })

                                    }


                                </tbody>
                            </table>
                        </div>





                        <Footer />

                    </div>


                </div>
            </div>




        </>
    )
}

export default WorkDetails