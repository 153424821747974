import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Decypt, tkn, url } from '../App';
import { HideLoading, LoginNow, Showloading, ShowSnack } from '../features/loginSlice';
import BSFIMG from './assets/logo.png'


function Login() {
  
  const navigate = useNavigate();
  const dispatches = useDispatch();
  const uselect = useSelector(state => state.loginSlice);


  const [user, setuser] = useState({ un: '', pw: '' })



  function LoginUsr() {

    if (user.un === '') {
      dispatches(ShowSnack({ msg: 'Usename cannot be blank', time: 4000 }));
    } else if (user.pw === '') {
      dispatches(ShowSnack({ msg: 'Password cannot be blank', time: 4000 }));
    } else {
      dispatches(Showloading("Authenticating, Please wait..."));

      fetch(`${url}userview/req/userviewright/`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
          'usr_ath': window.btoa(tkn)
        },
        body: JSON.stringify({ user: user.un, pwd: user.pw })
      }).then(result => result.json())
        .then(res => {
          res = JSON.parse(Decypt(res));
          // console.log(res);
          if (res.status === 'ok') {

            dispatches(LoginNow(res.msg));
            if(res.msg.usertype==='admin'){
              navigate('/admindashboard');
            }else if(res.msg.usertype==='user'){
              navigate('/dashboard');
            }else{
              dispatches(ShowSnack({msg:'There may be error in user information', time:4500}))
            }

          } else {
            // console.log(res.msg);
            dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
          }

        }).catch(err => {
          dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
        }).finally(() => {
          dispatches(HideLoading());
        })



      // dispatches(LoginNow({
      //   usr: user.un,
      //   logggedIn: true,
      //   sesstkn: '123456',
      //   localtkn: '123456'
      // }))
      // navigate('/dashboard')

      // // fetch Request and then loading hide 
      // dispatches(HideLoading());



    }

  }

  return (
    <>

      <div className='logincontainer'>

        <div className='loginform' >

          <div className='leftdiv leftdivlogin' >
            <img src={BSFIMG} alt="BSF" className='sideimage' />

          </div>

          <div className='rightdiv rightdivlogin' >

            {/* <img src={LOGO} alt="BSF" className='logoimg'/> */}
            <h2 style={{ marginTop: '15%', color: '#fff', textDecoration: 'underline' }}>FASTHOOK NETWORKS PVT LTD</h2>
            <h6 style={{ color: '#fff', marginBottom: '15px' }}>CALL : 9796275555</h6>

            {/* <h2 style={{ marginTop: '20%', color: '#fff', marginBottom: '15px' }}>Login</h2> */}

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20%', }}>
              <input type="text" value={user.un} onChange={(e) => { user.un = e.target.value; setuser({ ...user }) }} name="username" id="username" placeholder='Username' className='inputtxt' autoFocus={true} autoComplete='off' />
            </div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <input type="password" value={user.pw} onChange={(e) => { user.pw = e.target.value; setuser({ ...user }) }} name="password" id="password" placeholder='Password' className='inputtxt' autoComplete='off' />
            </div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={() => { LoginUsr() }} className='btn'> Login </button>
            </div>


          </div>

        </div>

      </div>

    </>
  )
}

export default Login