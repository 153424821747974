import './App.css';
import Login from './components/Login';
import {Routes, Route} from 'react-router-dom'
import Dashboard from './components/Dashboard';
import PreviousForms from './components/PreviousForms';
import AdminDashboard from './components/AdminDashboard';
import Loading from './components/Loading';
import { useSelector } from 'react-redux';
import Snackmsg from './components/Snackmsg';
import Users from './components/Users';
import ReviewApplication from './components/ReviewApplication';
import CryptoJS from 'crypto-js';

import aes from 'crypto-js/aes'
import encHex from 'crypto-js/enc-hex'
import padZeroPadding from 'crypto-js/pad-zeropadding'
import ZeroPadding from 'crypto-js/pad-zeropadding';
import FormList from './components/FormList';
import SalaryDetails from './components/SalaryDetails';
import WorkDone from './components/WorkDone';
import WorkDetails from './components/WorkDetails';
import ViewSalary from './components/ViewSalary';


const tkn = "CTKN_12398765-sUNILYishu_0987654rrr";
const url ='https://login.fhnpl.com/fhnpl/';
// const url ='http://127.0.0.1/fhnpl/';

const Encypt= (msg)=> {
  let key = encHex.parse("abcdef01234567890123456789abcdef");
  let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
  let encrypted = aes.encrypt(msg, key, {iv:iv, padding:padZeroPadding}).toString();
  return encrypted;  
}

const Decypt =(msg) => {
  let key = encHex.parse("abcdef01234567890123456789abcdef");
  let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
  let decrypted = aes.decrypt(msg, key, {iv:iv}).toString(CryptoJS.enc.Utf8);
  decrypted = decrypted.trim();
return decrypted;
}


function App() {
 const uselect = useSelector(state=> state.loginSlice);
//  console.log(Encypt(JSON.stringify({status:'ok', msg:'This is testing message to encrypt in js and decrypt in php'})));
//     console.log(JSON.parse(Decypt('diO9R6BUcZHHUKLhwg66woXztAc1tRfBHjNWaWf9ad+hSSWLXVDUL0ckjVjJ0rpXOAl9DBYdTyPoXjMsjrDtcw==')))


  return (
    <>
    {
      uselect.loading ===true ?<Loading />: <></>
    }
    
    {
      uselect.snack ===true ? <Snackmsg />:<></>
    }
    
    <Routes>
      <Route path='/' element={<Login />} />
      <Route  path='/dashboard/*' element={<Dashboard />} />
      <Route path='/previousforms' element={<PreviousForms />} />
      <Route path='/admindashboard' element={<AdminDashboard />} />
      <Route path='/users' element={<Users />} />
      <Route path='/reviewapplication' element={<ReviewApplication />} />
      <Route path='/formlist' element={<FormList />} />
      <Route path='/salarydetails' element={<SalaryDetails />} />
      <Route path='/workdone' element={<WorkDone />} />
      <Route path='/workdetails' element={<WorkDetails />} />
      <Route path='/viewsalary' element={<ViewSalary />} />
  
    </Routes>
    


    </>

  );
}

export default App;
export {tkn, url, Encypt, Decypt}
