import React, { useState } from 'react'
import { Decypt, tkn, url } from '../App';
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom';
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import Footer from './Footer';

function CafFormReview({ application, verifiedby }) {
    const navigate = useNavigate();
    const uselect = useSelector(state => state.loginSlice);
    const dispatches = useDispatch();
    const [customer, setCustomer] = useState(application)
    const [office, setoffice] = useState({
        kycverificationon:'',
        installedby:customer.user_name,
        empcode:customer.empcode,
        ippooltype:'',
        ipaddresspoolwan:'',
        ipaddresspoollan:'',
        ipaddresspoolbgp:'',
        cpemac:customer.transportmedia ==='OFC'?customer.onuontmac:customer.cpemac,
        bandwidth:customer.bandwidthplan,
        billdate:''
    })



    function OfficeVerify(){
        const {kycverificationon, installedby, empcode, ippooltype, ipaddresspoolwan, ipaddresspoollan, ipaddresspoolbgp, cpemac, bandwidth, billdate} = office;
        if ( kycverificationon===''){
            dispatches(ShowSnack({msg:`KYC Verification cannot be blank`, time:4000}))

        }else if( installedby===''){
            dispatches(ShowSnack({msg:`Installed by cannot be blank`, time:4000}))

        }else if( empcode===''){
            dispatches(ShowSnack({msg:`EMP code cannot be blank`, time:4000}))

        }else if(ippooltype ===''){
            dispatches(ShowSnack({msg:`IP Pool Type cannot be blank`, time:4000}))

        }else if(ipaddresspoolwan ===''){
            dispatches(ShowSnack({msg:`IP Address Pool Wan cannot be blank`, time:4000}))

        }else if(ipaddresspoollan ===''){
            dispatches(ShowSnack({msg:`IP Address Pool Lan  cannot be blank`, time:4000}))

        }else if(ipaddresspoolbgp ===''){
            dispatches(ShowSnack({msg:`IP Address Pool BGP  cannot be blank`, time:4000}))

        }else if(cpemac ===''){
            dispatches(ShowSnack({msg:`CPE MAC cannot be blank`, time:4000}))

        }else if(bandwidth ===''){
            dispatches(ShowSnack({msg:`Bandwidth cannot be blank`, time:4000}))

        }else if(billdate ===''){
            dispatches(ShowSnack({msg:`Bill Date cannot be blank`, time:4000}))

        }else if(customer.slno ===''){
            dispatches(ShowSnack({msg:`Request cannot be fullfilled. Please reload the page.`, time:4000}))
        } else{

            dispatches(HideSnack());
            dispatches(Showloading('Please Wait...'))

            fetch(`${url}caf/req/officeverification/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ kycverificationon, installedby, empcode, ippooltype, ipaddresspoolwan, ipaddresspoollan, ipaddresspoolbgp, cpemac, bandwidth, billdate, user:uselect.usr, slno:customer.slno })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        dispatches(ShowSnack({ msg: res.msg, time: 10000 }))
                        navigate('/admindashboard');               

                    } else {
                        // console.log(res.msg);
                        dispatches(ShowSnack({ msg: res.msg, time: 10000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })




        }

    }


    return (
        <React.Fragment>


            <div style={{ width: '100%', padding: '20px' }}>
                <h4 style={{ padding: '20px', textDecoration: '2px solid green underline', textAlign: 'center' }}>CUSTOMER APPLICATION FORM (CAF) INTERNET SERVICES</h4>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">APPLICATION SERIAL NO.: </label>
                {/* <label>{customer.slno}</label> */}
                <p>{customer.slno}</p>
                {/* <input type="text" y onChange={(e) => { customer.slno = e.target.value; setCustomer({ ...customer }) }} placeholder='APPLICATION SERIAL NO.' className='inputtxt1' autoComplete='off' disabled={true} /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">NAME OF CUSTOMER : </label>
                <p>{customer.customername}</p>
                {/* <input type="text" autoFocus={true} value={customer.customername} onChange={(e) => { customer.customername = e.target.value; setCustomer({ ...customer }) }} placeholder='NAME OF CUSTOMER' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">S/O, W/O, D/O : </label>
                <p>{customer.swdo}</p>
                {/* <input type="text" value={customer.swdo} onChange={(e) => { customer.swdo = e.target.value; setCustomer({ ...customer }) }} placeholder='S/O, W/O, D/O ' className='inputtxt1' autoComplete='off'  disabled={true} /> */}
            </div>


            <div className='forminput'>
                <label htmlFor="irla">ADDRESS : </label>
                <p>{customer.address}</p>
                {/* <input type="text" value={customer.address} onChange={(e) => { customer.address = e.target.value; setCustomer({ ...customer }) }} placeholder='ADDRESS ' className='inputtxt1' autoComplete='off'  disabled={true} /> */}
            </div>


            <div className='forminput'>
                <label htmlFor="irla">BLOCK : </label>
                <p>{customer.block}</p>
                {/* <input type="text" value={customer.block} onChange={(e) => { customer.block = e.target.value; setCustomer({ ...customer }) }} placeholder='BLOCK ' className='inputtxt1' autoComplete='off'  disabled={true} /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">TEHSIL : </label>
                <p>{customer.tehsil}</p>
                {/* <input type="text" value={customer.tehsil} onChange={(e) => { customer.tehsil = e.target.value; setCustomer({ ...customer }) }} placeholder='TEHSIL ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">DISTRICT : </label>
                <p>{customer.district}</p>
                {/* <input type="text" value={customer.district} onChange={(e) => { customer.district = e.target.value; setCustomer({ ...customer }) }} placeholder='DISTRICT ' className='inputtxt1' autoComplete='off'  disabled={true} /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">STATE/UT : </label>
                <p>{customer.state}</p>
                {/* <input type="text" value={customer.state} onChange={(e) => { customer.state = e.target.value; setCustomer({ ...customer }) }} placeholder='J&K ' className='inputtxt1' autoComplete='off' disabled={true} /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">PIN CODE : </label>
                <p>{customer.pincode}</p>
                {/* <input type="number" value={customer.pincode} onChange={(e) => { customer.pincode = e.target.value; setCustomer({ ...customer }) }} placeholder='PIN CODE ' className='inputtxt1' autoComplete='off'  disabled={true} /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">MOBILE NO.: </label>
                <p>{customer.mobile}</p>
                {/* <input type="number" value={customer.mobile} onChange={(e) => { customer.mobile = e.target.value; setCustomer({ ...customer }) }} placeholder='MOBILE NO. ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>



            <div className='forminput'>
                <label htmlFor="irla">EMAIL ID: </label>
                <p>{customer.email}</p>
                {/* <input type="text" value={customer.email} onChange={(e) => { customer.email = e.target.value; setCustomer({ ...customer }) }} placeholder='EMAIL ID ' className='inputtxt1' autoComplete='off' disabled={true} /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ALTERNATE MOBILE NO.: </label>
                <p>{customer.alternatemobile}</p>
                {/* <input type="number" value={customer.alternatemobile} onChange={(e) => { customer.alternatemobile = e.target.value; setCustomer({ ...customer }) }} placeholder='ALTERNATE MOBILE NO. ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">AADHAR NO.: </label>
                <p>{customer.aadhar}</p>
                {/* <input type="number" value={customer.aadhar} onChange={(e) => { customer.aadhar = e.target.value; setCustomer({ ...customer }) }} placeholder='AADHAR NO. ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ELECTION ID NO.: </label>
                <p>{customer.electionid}</p>
                {/* <input type="text" value={customer.electionid} onChange={(e) => { customer.electionid = e.target.value; setCustomer({ ...customer }) }} placeholder='ELECTION ID NO. ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">PAN/GSTIN: </label>
                <p>{customer.pangstin}</p>
                {/* <input type="text" value={customer.pangstin} onChange={(e) => { customer.pangstin = e.target.value; setCustomer({ ...customer }) }} placeholder='PAN/GSTIN ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ANY OTHER POI/POA: </label>
                <p>{customer.anyothpoipoa}</p>
                {/* <input type="text" value={customer.anyothpoipoa} onChange={(e) => { customer.anyothpoipoa = e.target.value; setCustomer({ ...customer }) }} placeholder='ANY OTHER POI/POA ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">CONTACT PERSON: </label>
                <p>{customer.contactperson}</p>
                {/* <input type="text" value={customer.contactperson} onChange={(e) => { customer.contactperson = e.target.value; setCustomer({ ...customer }) }} placeholder='CONTACT PERSON ' className='inputtxt1' autoComplete='off' /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">PoP: </label>
                <p>{customer.pop}</p>
                {/* <input type="text" value={customer.pop} onChange={(e) => { customer.pop = e.target.value; setCustomer({ ...customer }) }} placeholder='PoP ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">ZONE ID IF ANY: </label>
                <label htmlFor="irla">{customer.zoneidifany}</label>
                {/* <input type="text" value={customer.zoneidifany} onChange={(e) => { customer.zoneidifany = e.target.value; setCustomer({ ...customer }) }} placeholder='ZONE ID  ' className='inputtxt1' autoComplete='off'  disabled={true}/> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Transport Media: </label>
                <p>{customer.transportmedia}</p>
                {/* <select className='inputtxt1' value={customer.transportmedia} onChange={(e) => { customer.transportmedia = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="OFC">OFC</option>
                    <option value="Radio">Radio</option>
                    <option value="Satellite">Satellite</option>
                    <option value="DSL">DSL</option>
                    <option value="CAT5/6">CAT5/6</option>
                    <option value="Coaxial Cable">Coaxial Cable</option>
                </select> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Last mile access provider: </label>
                <p>{customer.lastmileaccessprovider}</p>
                {/* <input type="text" value={customer.lastmileaccessprovider} onChange={(e) => { customer.lastmileaccessprovider = e.target.value; setCustomer({ ...customer }) }} placeholder='Last mile access provider ' className='inputtxt1' autoComplete='off' /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">TYPE OF CUSTOMER: </label>
                <p>{customer.typeofcustomer}</p>
                {/* <select className='inputtxt1' value={customer.typeofcustomer} onChange={(e) => { customer.typeofcustomer = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="Corporate">Corporate</option>
                    <option value="OSP">OSP</option>
                    <option value="Home User">Home User</option>
                    <option value="Government">Government</option>
                </select> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">INSTALLATION ADDRESS WITH LANDMARK : </label>
                <p>{customer.instllationaddress}</p>
                {/* <textarea value={customer.instllationaddress} onChange={(e) => { customer.instllationaddress = e.target.value; setCustomer({ ...customer }) }} placeholder='INSTALLATION ADDRESS' className='inputtxt1' autoComplete='off' cols="30" rows="5"></textarea> */}
            </div>

            <div className='forminput'>

            </div>



            <div className='forminput' style={{ width: '98%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>


                <h3 style={{ width: '100%' }}>Availability of :- </h3> <br />
                <div className='forminput checkinput' style={{ flexDirection: 'row', alignItems: 'center', border: 'transparent' }}>
                    <label htmlFor="irla">a) UDP: </label>

                    <div style={{ paddingLeft: '10px' }}>
                        <label htmlFor="udp">{customer.udp === 'no' ? 'NO' : 'Yes'}</label>
                    </div>

                    {/* <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.udp === 'no' ? false : true} onChange={() => { customer.udp === 'no' ? customer.udp = 'yes' : customer.udp = 'no'; setCustomer({ ...customer }) }} name="udp" id="udp" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="udp">Yes</label>
                    </div>

                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.udp === 'yes' ? false : true} onChange={() => { customer.udp === 'no' ? customer.udp = 'yes' : customer.udp = 'no'; setCustomer({ ...customer }) }} name="udpno" id="udpno" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="udpno">No</label>
                    </div> */}

                </div>

                <div className='forminput checkinput' style={{ flexDirection: 'row', alignItems: 'center', border: 'transparent' }}>
                    <label htmlFor="irla">b) MRTG: </label>
                    <div style={{ paddingLeft: '10px' }}>
                        <label htmlFor="MRTG">{customer.mrtg === 'no' ? 'No' : 'Yes'}</label>
                    </div>

                    {/* <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.mrtg === 'no' ? false : true} onChange={() => { customer.mrtg === 'no' ? customer.mrtg = 'yes' : customer.mrtg = 'no'; setCustomer({ ...customer }) }} name="MRTG" id="MRTG" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="MRTG">Yes</label>
                    </div>

                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.mrtg === 'yes' ? false : true} onChange={() => { customer.mrtg === 'no' ? customer.mrtg = 'yes' : customer.mrtg = 'no'; setCustomer({ ...customer }) }} name="MRTGNO" id="MRTGNO" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="MRTGNO">No</label>
                    </div> */}

                </div>


                <div className='forminput checkinput' style={{ flexDirection: 'row', alignItems: 'center', border: 'transparent' }}>
                    <label htmlFor="irla">c) CAF: </label>
                    <div style={{ paddingLeft: '10px' }}>
                        <label htmlFor="CAF">{customer.caf === 'no' ? 'No' : 'Yes'}</label>
                    </div>

                    {/* <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.caf === 'no' ? false : true} onChange={() => { customer.caf === 'no' ? customer.caf = 'yes' : customer.caf = 'no'; setCustomer({ ...customer }) }} name="CAF" id="CAF" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="CAF">Yes</label>
                    </div>

                    <div style={{ paddingLeft: '10px' }}>
                        <input type="checkbox" checked={customer.caf === 'yes' ? false : true} onChange={() => { customer.caf === 'no' ? customer.caf = 'yes' : customer.caf = 'no'; setCustomer({ ...customer }) }} name="CAFNO" id="CAFNO" style={{ marginLeft: '5px', marginRight: '10px' }} />
                        <label htmlFor="CAFNO">No</label>
                    </div> */}

                </div>




            </div>


            <div className='forminput'>
                <label htmlFor="irla">BANDWIDTH/PLAN: </label>
                <p>{customer.bandwidthplan}</p>
                {/* <input type="text" value={customer.bandwidthplan} onChange={(e) => { customer.bandwidthplan = e.target.value; setCustomer({ ...customer }) }} placeholder='BANDWIDTH/PLAN ' className='inputtxt1' autoComplete='off' /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">CUSTOMER BUILDING LAT: </label>
                <p>{customer.lat}</p>
                {/* <input type="text" value={customer.lat} onChange={(e) => { customer.lat = e.target.value; setCustomer({ ...customer }) }} placeholder='LATITUDE ' className='inputtxt1' autoComplete='off' /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">CUSTOMER BUILDING LONG: </label>
                <p>{customer.longt}</p>
                {/* <input type="text" value={customer.longt} onChange={(e) => { customer.longt = e.target.value; setCustomer({ ...customer }) }} placeholder='LONGITUDE ' className='inputtxt1' autoComplete='off' /> */}
            </div>



            <div className='forminput'>
                <label htmlFor="irla">CPE/ ONU DISTANCE FROM STREET POLE / BTS: </label>
                <p>{customer.cpeonudistancefromstreet}</p>
                {/* <input type="text" value={customer.cpeonudistancefromstreet} onChange={(e) => { customer.cpeonudistancefromstreet = e.target.value; setCustomer({ ...customer }) }} placeholder='CPE/ ONU DISTANCE FROM STREET POLE / BTS ' className='inputtxt1' autoComplete='off' /> */}
            </div>

            <div className='forminput'>
                <label htmlFor="irla">TERMINATION TYPE: OFC/ CAT 6 LENGTH IN METERS: </label>
                <p>{customer.terminationtype}</p>
                {/* <input type="text" value={customer.terminationtype} onChange={(e) => { customer.terminationtype = e.target.value; setCustomer({ ...customer }) }} placeholder='TERMINATION TYPE ' className='inputtxt1' autoComplete='off' /> */}
            </div>


            <div className='forminput'>
                <label htmlFor="irla">CPE MAC: </label>
                <p>{customer.cpemac}</p>
                {/* <input type="text" value={customer.cpemac} onChange={(e) => { customer.cpemac = e.target.value; setCustomer({ ...customer }) }} placeholder='CPE MAC ' className='inputtxt1' autoComplete='off' /> */}
            </div>


            <div className='forminput'>
                <label htmlFor="irla">ONU/ONT MAC: </label>
                <p>{customer.onuontmac}</p>
                {/* <input type="text" value={customer.onuontmac} onChange={(e) => { customer.onuontmac = e.target.value; setCustomer({ ...customer }) }} placeholder='ONU/ONT MAC ' className='inputtxt1' autoComplete='off' /> */}
            </div>


            <div className='forminput'>
                <label htmlFor="irla">WI-FI ROUTER MAC: </label>
                <p>{customer.wifiroutermac}</p>
                {/* <input type="text" value={customer.wifiroutermac} onChange={(e) => { customer.wifiroutermac = e.target.value; setCustomer({ ...customer }) }} placeholder='WI-FI ROUTER MAC' className='inputtxt1' autoComplete='off' /> */}
            </div>
            <div className='forminput hideforminput'> {/* Blank  */}     </div>



            <div className='forminput'>

                <div style={{ width: '92%' }}>
                    <div style={{maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>

                        <a href={`${url}/${customer.aadharfront}`} target="_blank">
                            <img src={`${url}/${customer.aadharfront}`} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                            <p style={{ fontSize: '10px' }}> Aadhar Front </p>
                        </a>
                    </div>
                </div>

            </div>




            <div className='forminput'>

                <div style={{ width: '92%' }}>
                    <div style={{maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        <a href={`${url}/${customer.aadharback}`} target="_blank">
                            <img src={`${url}/${customer.aadharback}`} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                            <p style={{ fontSize: '10px' }}>Aadhar Back</p>
                        </a>
                    </div>
                </div>

            </div>


            <div className='forminput'>
                <div style={{ width: '92%' }}>
                    <div style={{ maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        <a href={`${url}/${customer.idproof2front}`} target="_blank">
                            <img src={`${url}/${customer.idproof2front}`} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                            <p style={{ fontSize: '10px' }}>Other ID Front</p>
                        </a>
                    </div>
                </div>
            </div>



            <div className='forminput'>

                <div style={{ width: '92%' }}>
                    <div style={{ maxWidth: '70%', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        <a href={`${url}/${customer.idproof2back}`} target="_blank">
                            <img src={`${url}/${customer.idproof2back}`} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                            <p style={{ fontSize: '10px' }}>Other ID back</p>
                        </a>
                    </div>
                </div>

            </div>


            <div className='forminput'>

                <div style={{ width: '92%' }}>
                    <div style={{ width: '120px', height: "120px", border: "1px solid grey", borderRadius: '3px', marginTop: '5px' }}>
                        <a href={`${url}/${customer.imagesrc}`} target="_blank">
                            <img src={`${url}/${customer.imagesrc}`} alt="uploaded_user" style={{ width: '100%', height: "100%" }} />
                            <p style={{ fontSize: '10px' }}>Image Preview</p>
                        </a>
                    </div>
                </div>

            </div>



            <div className='forminput'> {/* Blank  */}     </div>

            <div className='forminput' style={{ flexDirection: 'row', width: '98%', paddingBottom: '20px' }}>
                <input type="checkbox" checked={customer.agreement} onChange={(e) => { customer.agreement = !(customer.agreement); setCustomer({ ...customer }) }} name="agreement" id="agreement" style={{ marginTop: '15px' }} disabled={true} />
                <label htmlFor="agreement" style={{ fontSize: '12px' }}>
                    I hereby declare that I have read and understood all the terms & conditions as mentioned below / overleaf / at
                    https://fhnpl.com  and hereby agree to all these. I also declare that information provided by me above is true
                    and correct, if found wrong / fake at any later stage, I will be fully responsible for the same. . I declare
                    and certify that I / we will be fully responsible for the usage, management, physical / technical security of
                    the CPE installed for the internet services. I/we certify that from allotted IP there will be no anti-national
                    activities via social networking, proxies, VPN, VPs , encrypted files containing any sort of video/photo/media
                    file / document uploaded to any web platform or messaging service . I/we will provide complete access to all IT
                    & ITeS content and infrastructure as and when required by any government authority / security agencies. I/we
                    further declare that I/we will honour all the bills raised by FHNPL for the services availed by me / us / we .
                    I/we are not undertaking the following activities which are illegal and violate the terms of agreement with
                    FHNPL: a) Terminating Internet telephony calls to any public telephone number (PSTN/ISDN/PLMN etc.) in India,
                    irrespective of whether the same originates in India or abroad [i.e. establishing connection between Internet
                    / ILL and PRI/SIPT/GSM Gateway/Mobile SIM for Voice communication from anywhere to anywhere by means of
                    dialling a telephone number (PSTN/ ISDN/ PLMN)].b) Deploying Wifi on my/our own without centralized
                    authentication or without password protection’s) c) Undertake to comply with all applicable laws and
                    regulations, including without limitation, the provisions of the Indian Telegraph Rules, 1951 made
                    thereunder, Indian Wireless Act 1933, IT Act 2000 and TRAI Act 1997 and any amendments or replacements made
                    thereto from time to time .d) I/We shall not re-sell the services procured from FHNPL to any third party or
                    any other person. e) I / We Ensure that objectionable, obscene, unauthorized or any other content, messages
                    or communications infringing copyright, Intellectual property right and international & domestic cyber laws,
                    in any form or inconsistent with the laws of India, are not carried out by us .f)I certify that minimum
                    contract period of the service will be 1 Year and in case of foreclosure by me/we/us we will pay full 1
                    year (Annual) service costs towards FHNPL.
                </label>
            </div>

            <div className='forminput'>
                <label htmlFor="irla" style={{textAlign:'center'}}>( {verifiedby} )</label>
                <p style={{textAlign:'center'}}>Witness/ Verification by</p>
            </div>
            
            <div className='forminput hideforminput'>
             
            </div>
            
            <div className='forminput'>
                <label htmlFor="irla" style={{textAlign:'center', color:'grey'}}>( Verified by mobile OTP )</label>
                <p style={{textAlign:'center'}}>Customer Name : {customer.customername}</p>
            </div>
          

            <div style={{ width: '100%', borderTop: "1px dotted #b49700", marginTop: '3px' }}></div>
            <div style={{ width: '100%', borderTop: "1px dotted #b49700", marginTop: '2px', marginBottom: '10px' }}></div>


            <div style={{ width: '100%', padding: '20px' }}>
                <h4 style={{ padding: '20px',  textAlign: 'center' }}>------- FOR OFFICE USE ONLY -------</h4>
            </div>


            <div className='forminput'>
                <label htmlFor="irla">KYC Verification On : </label>
                <input type="date" value={office.kycverificationon} onChange={(e) => { office.kycverificationon = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='KYC Verification on ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Installed By : </label>
                <input type="text" value={office.installedby} onChange={(e) => { office.installedby = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='Installed By ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">EMP Code : </label>
                <input type="text" value={office.empcode} onChange={(e) => { office.empcode = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='EMP Code ' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">IP Pool Type : </label>
                {/* <input type="text" value={office.ippooltype} onChange={(e) => { office.ippooltype = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='IP Pool Type' className='inputtxt1' autoComplete='off' /> */}
                <select className='inputtxt1' value={office.ippooltype} onChange={(e) => { office.ippooltype = e.target.value; setCustomer({ ...customer }) }}>
                    <option value="">Select</option>
                    <option value="PUBLIC">PUBLIC</option>
                    <option value="PRIVATE">PRIVATE</option>
                </select>

            </div>

            <div className='forminput'>
                <label htmlFor="irla">IP Address Pool: WAN : </label>
                <input type="text" value={office.ipaddresspoolwan} onChange={(e) => { office.ipaddresspoolwan = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='IP Address Pool:WAN' className='inputtxt1' autoComplete='off' />
            </div>
            <div className='forminput'>
                <label htmlFor="irla">IP Address Pool: LAN : </label>
                <input type="text" value={office.ipaddresspoollan} onChange={(e) => { office.ipaddresspoollan = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='IP Address Pool:LAN' className='inputtxt1' autoComplete='off' />
            </div>
            <div className='forminput'>
                <label htmlFor="irla">IP Address Pool: BGP : </label>
                <input type="text" value={office.ipaddresspoolbgp} onChange={(e) => { office.ipaddresspoolbgp = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='IP Address Pool:BGP' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">CPE MAC: </label>
                <input type="text" value={office.cpemac} onChange={(e) => { office.cpemac = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='CPE MAC' className='inputtxt1' autoComplete='off' />
            </div>
            <div className='forminput'>
                <label htmlFor="irla">Bandwidth: </label>
                <input type="text" value={office.bandwidth} onChange={(e) => { office.bandwidth = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='Bandwidth' className='inputtxt1' autoComplete='off' />
            </div>

            <div className='forminput'>
                <label htmlFor="irla">Bill Date: </label>
                <input type="date" value={office.billdate} onChange={(e) => { office.billdate = e.target.value.toUpperCase(); setoffice({ ...office }) }} placeholder='Billdate' className='inputtxt1' autoComplete='off' />
            </div>
            <div className='forminput'>
            </div>
            <div className='forminput hideforminput'>
            </div>




            
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <button onClick={() => OfficeVerify()} className='btn1'> Submit </button>
            </div>




            <Footer />
        </React.Fragment>
    )
}

export default CafFormReview