import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Decypt, Encypt, tkn, url } from '../App';
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import Navigation from './Navigation'
import Sidebar from './Sidebar'

function AdminDashboard() {
  const navigate = useNavigate();
  const dispatches = useDispatch();
  const uselect = useSelector(state => state.loginSlice);
  const [applications, setapplications] = useState([]);

  useEffect(() => {
   

    dispatches(Showloading("Retrieving Applications, Please wait..."));
    fetch(`${url}cafview/req/viewforms/`, {
      method: "post",
      headers: {
        'Content-Type': 'application/json',
        'usr_ath': window.btoa(tkn)
      },
      body: JSON.stringify({ user: uselect.usr })
    }).then(result => result.json())
      .then(res => {
        res = JSON.parse(Decypt(res));
        // console.log(res);
      

        if (res.status === 'ok') {
          // reset
          setapplications(res.msg);

        } else {
          // console.log(res.msg);
          dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
        }

      }).catch(err => {
        dispatches(ShowSnack({ msg: ' Something went wrong. '+err, time: 4000 }))
      }).finally(() => {
        dispatches(HideLoading());
      })

    return () => {

    }
  }, [])


  function DownloadCaf(slno){
    dispatches(Showloading('Please wait while we are downloading the form...'));
    if(slno===''){
      dispatches(ShowSnack({ msg: 'Invalid request found', time: 4000 }));
    }else{
      dispatches(HideSnack());
      const data={slno}
      fetch(`${url}cafdownload/req/downloadcaf/`, {
        method:'post',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify(data)
      }).then(result => result.blob())
      .then(blob => {
        let a = document.createElement("a");
        // let file = window.URL.createObjectURL(blob);
        a.href=URL.createObjectURL(blob);
        a.setAttribute("download", slno+'.pdf');
        a.click();
        // window.location.assign(file);
      }).catch(err => {
        dispatches(ShowSnack({ msg: 'Unable to download the file', time: 4000 }));
      }).finally(()=> {
        dispatches(HideLoading());
      })




    }

  }


  return (
    <>

      <Navigation />

      <div className='bodybar'>
        <div className='sidebar'>
          <Sidebar />
        </div>

        <div className="rightbar">

          <div className='dashboarduser' >


            <div className="previousforms">
              <h3 style={{ paddingTop: '15px', paddingBottom: '15px' }}>Applications</h3>

              <div className='previousformsheading' style={{fontSize:"smaller"}}>
                <div>
                  <p style={{ fontWeight: 'bold' }}>Application Id</p>
                  <p style={{ fontWeight: 'bold' }}> (Customer Name) </p>
                  <p style={{ fontWeight: 'bold' }}> <u>(Status)</u></p>
                </div>

                <div> <p style={{ fontWeight: 'bold' }}>Action</p></div>

              </div>


              {
                applications.map((val, indx) => {
                  return (
                    <div key={indx} className='previousformsheading' style={{fontSize:"smaller"}}>
                      <div>
                        <p>{val.slno}</p>
                        <p> ({val.customername}) </p>
                        <p style={{ fontWeight: 'bold' }}> <u>({val.appstatus})</u></p>
                      </div>

                      <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center',}}>
                        <>
                        {
                          val.appstatus==='Approved'?<button className='btntbl' onClick={()=> DownloadCaf(val.slno)} style={{backgroundColor:'green'}}>Download</button>:
                          val.appstatus==='Pending'?<button onClick={()=> { navigate('/reviewapplication', {state:{slno:val.slno, verifiedby:val.user}}) }} className='btntbl'>View</button>:
                          <></>
                        }
                        </>
                      </div>
                    </div>
                  );
                })
              }


            </div>



          </div>

        </div>
      </div>
      

    </>
  )
}

export default AdminDashboard