import React from 'react'
import { useSelector } from 'react-redux'

function Loading() {
    const uselect = useSelector(state => state.loginSlice);
    return (
        <>
            <div className="loading">
                <div className="loadingcontainer">
                    <div className="spinner"></div>
                    <div className="spinnermsg">
                        <p> {uselect.loadingtxt} </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loading