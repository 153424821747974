import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Decypt, Encypt, tkn, url } from '../App';
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import Navigation from './Navigation'
import Sidebar from './Sidebar'

function PreviousForms() {

    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const [applications, setapplications] = useState([]);

    useEffect(() => {

        dispatches(Showloading("Retrieving Applications, Please wait..."));
        fetch(`${url}cafview/req/previousforms/`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'usr_ath': window.btoa(tkn)
            },
            body: JSON.stringify({ user: Encypt(uselect.usr) })
        }).then(result => result.json())
            .then(res => {
                res = JSON.parse(Decypt(res));
                if (res.status === 'ok') {
                    // reset
                    setapplications(res.msg);

                } else {
                    // console.log(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                }

            }).catch(err => {
                dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
            }).finally(() => {
                dispatches(HideLoading());
            })

        return () => {

        }
    }, [])


    function DownloadCaf(slno){
        dispatches(Showloading('Please wait while we are downloading the form...'));
        if(slno===''){
          dispatches(ShowSnack({ msg: 'Invalid request found', time: 4000 }));
        }else{
          dispatches(HideSnack());
          const data={slno}
          fetch(`${url}cafdownload/req/downloadcaf/`, {
            method:'post',
            headers:{
              'Content-Type':'application/json'
            },
            body:JSON.stringify(data)
          }).then(result => result.blob())
          .then(blob => {
            let a = document.createElement("a");
            // let file = window.URL.createObjectURL(blob);
            a.href=URL.createObjectURL(blob);
            a.setAttribute("download", slno+'.pdf');
            a.click();
            // window.location.assign(file);
          }).catch(err => {
            dispatches(ShowSnack({ msg: 'Unable to download the file', time: 4000 }));
          }).finally(()=> {
            dispatches(HideLoading());
          })
    
    
    
    
        }
    
      }

    return (

        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser' >


                        <div className="previousforms">
                            <h3 style={{ paddingTop: '15px', paddingBottom: '15px' }}>Form History</h3>

                            <div className='previousformsheading'>
                                <div>
                                    <p style={{ fontWeight: 'bold' }}>Application Id</p>
                                    <p style={{ fontWeight: 'bold' }}> (Customer Name) </p>
                                </div>
                                <div> <p style={{ fontWeight: 'bold' }}>Status</p></div>
                            </div>


                            {
                                applications.map((val, indx) => {
                                    return (
                                        <div key={indx} className='previousformsheading'>
                                            <div>
                                                <p>{val.slno}</p>
                                                <p> ({val.customername}) </p>
                                            </div>

                                            <div>
                                                {
                                                    val.engineerverified ==='no'? <p>Engineer verification is required</p>:<p>{val.appstatus}</p>
                                                }
                                                

                                                {
                                                    val.appstatus === 'Approved' ?
                                                        <>
                                                            {/* <br />
                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', border: 'transparent' }}>
                                                                <button onClick={() => DownloadCaf(val.slno)} className='btntbl'>Download</button>
                                                            </div> */}

                                                        </>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }


                        </div>


                    </div>

                </div>
            </div>

        </>
    )
}

export default PreviousForms