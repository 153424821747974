import React from 'react'
import CafForm from './CafForm'
import Footer from './Footer'
import Navigation from './Navigation'
import Sidebar from './Sidebar'

function Dashboard() {


    return (
        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser' >


                        <CafForm />




                        <Footer />

                    </div>


                </div>
            </div>




        </>

    )
}

export default Dashboard