import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import { Decypt, tkn, url } from './../App'
import Footer from './Footer';
import Navigation from './Navigation'
import Sidebar from './Sidebar'

function Users() {
    const uselect = useSelector(state => state.loginSlice);
    const dispatches = useDispatch();
    const [user, setuser] = useState({ usr: '', pwd: '', user_name: '', mobile: '', designation: '', empcode: '' })
    const [userlists, setuserlists] = useState(null);
    const [refreshlist, setrefreshlist] = useState(false)
    const [updatebtn, setupdatebtn] = useState(false)

    useEffect(() => {

        dispatches(Showloading("Retrieving Users, Please wait..."));
        fetch(`${url}userview/req/viewusers/`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'usr_ath': window.btoa(tkn)
            },
            body: JSON.stringify({ user: uselect.usr })
        }).then(result => result.json())
            .then(res => {
                res = JSON.parse(Decypt(res));
                // console.log(res);

                if (res.status === 'ok') {
                    // reset
                    setuserlists(res.msg);

                } else {
                    // console.log(res.msg);
                    dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                }

            }).catch(err => {
                dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
            }).finally(() => {
                dispatches(HideLoading());
            })

        return () => {

        }
    }, [refreshlist])

    function CreateNewUser() {
        const { usr, pwd, user_name, mobile, empcode, designation } = user;
        if (usr === '' || usr.length < 5) {
            dispatches(ShowSnack({ msg: 'Minimum Five characters are required for username. ', time: 4000 }))
        } else if (pwd === '' || pwd.length < 7) {
            dispatches(ShowSnack({ msg: ' Minimum length of password required is 7. ', time: 4000 }))
        } else if (user_name === '') {
            dispatches(ShowSnack({ msg: ' Name of user cannot be blank. ', time: 4000 }))
        } else if (mobile.length !== 10) {
            dispatches(ShowSnack({ msg: ' Mobile number should be of 10 digits only. ', time: 4000 }))
        } else if (designation === '') {
            dispatches(ShowSnack({ msg: ' Designation should never be blank. ', time: 4000 }))
        } else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/CreateUser/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr, usr, pwd, user_name, mobile, empcode, designation })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setrefreshlist(!refreshlist);
                        user.user_name = '';
                        user.pwd = '';
                        user.usr = '';
                        user.mobile = '';
                        user.empcode = '';
                        user.designation = '';
                        setuser({ ...user });
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }
    }

    function UpdateInputs(val) {
        user.usr = val.userid;
        user.user_name = val.user_name;
        user.mobile = val.mobile;
        user.empcode = val.empcode;
        user.designation = val.designation;
        user.pwd = '';
        setuser({ ...user });
        setupdatebtn(true);

    }


    function UpdateUser() {
        const { usr, pwd, user_name, mobile, empcode, designation } = user;
        if (usr === '' || usr.length < 5) {
            dispatches(ShowSnack({ msg: 'Minimum Five characters are required for username. ', time: 4000 }))
        } else if (pwd === '' || pwd.length < 7) {
            dispatches(ShowSnack({ msg: ' Minimum length of password required is 7. ', time: 4000 }))
        } else if (user_name === '') {
            dispatches(ShowSnack({ msg: ' Name of user cannot be blank. ', time: 4000 }))
        } else if (mobile.length !== 10) {
            dispatches(ShowSnack({ msg: ' Mobile number should be of 10 digits only. ', time: 4000 }))
        } else if (designation === '') {
            dispatches(ShowSnack({ msg: ' Designation should never be blank. ', time: 4000 }))
        } else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/UpdateUser/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr, usr, pwd, user_name, mobile, empcode, designation })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setrefreshlist(!refreshlist);
                        user.user_name = '';
                        user.pwd = '';
                        user.usr = '';
                        user.mobile = '';
                        user.empcode = '';
                        user.designation = '';
                        setuser({ ...user });
                        setupdatebtn(false);
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }
    }


    return (
        <>

            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='admindashboard' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap" }}>
                        <>
                            <div style={{ width: '100%', paddingTop: '20px', paddingBottom: "20px" }}>
                                <h4 style={{ padding: '20px', textDecoration: '2px solid green underline', textAlign: 'center' }}>Create New Users</h4>
                            </div>
                            <div className='forminput'>
                                <label htmlFor="irla">Name of User: </label>
                                <input type="text" value={user.user_name} disabled={updatebtn} onChange={(e) => { user.user_name = e.target.value; setuser({ ...user }) }} placeholder='Name of user' className='inputtxt1' autoComplete='off' />
                            </div>
                            <div className='forminput'>
                                <label htmlFor="irla">Create Username (without space): </label>
                                <input type="text" disabled={updatebtn} value={user.usr} onChange={(e) => { user.usr = e.target.value; setuser({ ...user }) }} placeholder='Username' className='inputtxt1' autoComplete='off' />
                            </div>
                            <div className='forminput'>
                                <label htmlFor="irla">Create Password: </label>
                                <input type="password" value={user.pwd} onChange={(e) => { user.pwd = e.target.value; setuser({ ...user }) }} placeholder='Password' className='inputtxt1' autoComplete='off' />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Mobile No: </label>
                                <input type="number" value={user.mobile} onChange={(e) => { user.mobile = e.target.value; setuser({ ...user }) }} placeholder='Mobile Number(10 digits)' className='inputtxt1' autoComplete='off' />
                            </div>


                            <div className='forminput'>
                                <label htmlFor="irla">Employee Code: </label>
                                <input type="text" value={user.empcode} onChange={(e) => { user.empcode = e.target.value; setuser({ ...user }) }} placeholder='Employee Code' className='inputtxt1' autoComplete='off' />
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Designation: </label>
                                <input type="text" value={user.designation} onChange={(e) => { user.designation = e.target.value; setuser({ ...user }) }} placeholder='Designation' className='inputtxt1' autoComplete='off' />
                            </div>


                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    updatebtn === false ? <button onClick={() => { CreateNewUser() }} className='btn1'> Submit </button> :
                                        <button onClick={() => { UpdateUser() }} className='btn1'> Update </button>
                                }

                            </div>

                        </>





                        <>
                            <div style={{ width: '100%', paddingTop: '30px', paddingBottom: "20px" }}>
                                <h4 style={{ padding: '20px', textDecoration: '2px solid green underline' }}>List of Users</h4>
                            </div>

                            <div className="usrtbl" style={{ marginBottom: '30px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Name of Employee</th>
                                            <th>Username</th>
                                            <th>Employee Code</th>
                                            <th>Designation</th>
                                            <th>Mobile No</th>
                                            <th>Created On</th>
                                            <th>Reset Password</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userlists === null ?
                                                <>
                                                    <tr>
                                                        <td colSpan={8} style={{ color: 'brown', textAlign: 'center' }}> At present there is no user </td>
                                                    </tr>
                                                </>
                                                :
                                                userlists.map((val, indx) => {
                                                    return (
                                                        <tr key={indx}>
                                                            <td>{indx + 1}</td>
                                                            <td>{val.user_name} </td>
                                                            <td>{val.userid}</td>
                                                            <td>{val.empcode}</td>
                                                            <td>{val.designation}</td>
                                                            <td> {val.mobile} </td>
                                                            <td>{val.createdon} </td>
                                                            <td><button onClick={() => UpdateInputs(val)} className='btntbl'>Reset</button></td>
                                                        </tr>
                                                    );
                                                })

                                        }


                                    </tbody>
                                </table>
                            </div>


                        </>

                    </div>

                </div>
            </div>

            <Footer />

        </>
    )
}

export default Users