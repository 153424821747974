import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Decypt, tkn, url } from '../App';
import { HideLoading, HideSnack, Showloading, ShowSnack } from '../features/loginSlice';
import Navigation from './Navigation'
import SalarySlip from './SalarySlip';
import Sidebar from './Sidebar'


function ViewSalary() {
    const dispatches = useDispatch();
    const uselect = useSelector(state => state.loginSlice);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const year = new Date().getFullYear();
    const currentmonth = new Date().getMonth();
    const [viewslip, setviewslip] = useState({ year: year, month: months[currentmonth], uid: uselect.usr })
    const [slip, setslip] = useState(null)


    function ViewPaySlip() {
        if (viewslip.month === '') {
            dispatches(ShowSnack({ msg: 'Please select month. ', time: 4000 }))
        } else if (viewslip.year === '') {
            dispatches(ShowSnack({ msg: 'Year is required. ', time: 4000 }))
        }  else {

            dispatches(HideSnack());
            dispatches(Showloading("Please wait..."));
            fetch(`${url}userview/req/viewpayslip/`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'usr_ath': window.btoa(tkn)
                },
                body: JSON.stringify({ user: uselect.usr, viewslip })
            }).then(result => result.json())
                .then(res => {
                    res = JSON.parse(Decypt(res));
                    // console.log(res);

                    if (res.status === 'ok') {
                        // reset
                        setslip(res.msg);

                    } else {
                        dispatches(ShowSnack({ msg: res.msg, time: 4000 }))
                    }

                }).catch(err => {
                    dispatches(ShowSnack({ msg: ' Something went wrong. ', time: 4000 }))
                }).finally(() => {
                    dispatches(HideLoading());
                })

        }
    }




    return (
        <>


            <Navigation />

            <div className='bodybar'>
                <div className='sidebar'>
                    <Sidebar />
                </div>

                <div className="rightbar">

                    <div className='dashboarduser1' >



                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                            <h4 style={{ width: "100%", color: "#b47104", paddingTop: '10px' }}>View PaySlip</h4>

                            <div className='forminput'>
                                <label htmlFor="irla">Month: </label>
                                <select className='inputtxt1' value={viewslip.month} onChange={(e) => { viewslip.month = e.target.value; setviewslip({ ...viewslip }) }}>
                                    {
                                        months.map((val, indx) => (
                                            <option key={indx} value={val}>{val}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className='forminput'>
                                <label htmlFor="irla">Year:</label>
                                <input type="number" className='inputtxt1' value={viewslip.year} onChange={(e) => { viewslip.year = e.target.value; setviewslip({ ...viewslip }) }} />

                            </div>

                            <div className='forminput' >
                                <button style={{ backgroundColor: "#b47104" }} onClick={() => { ViewPaySlip() }} className='btn1'>View</button>
                            </div>

                        </div>




                        {
                            slip === null ?
                                <>
                                    <h4 style={{ width: "100%", color: "brown", paddingTop: '10px', textAlign: "center" }}>No Payslip</h4>
                                </> :
                                <SalarySlip salaryslip={slip} />
                        }


                    </div>


                </div>
            </div>




        </>
    )
}

export default ViewSalary